.grabIconButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  touch-action: none; // MANUAL OVERRIDE

  .button {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
  }
  
}
