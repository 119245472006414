.tableRowDefaultTestResponseDefault,
.tableRowBooleanTestResponseDefault {
  .variableName {
    display: flex;
    width: 20.96rem;
    flex: none;
    overflow-x: auto;
  }
}

.tableRowVariableKeyValueDefault {
  .keyInput {
    width: 17.25rem;
  }

  .variableValueInput {
    width: 17.25rem;

    div {
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
}
