.inputFieldDefault {
  align-items: center;

  .textarea,
  .input {
    color: rgb(135, 141, 155);
    width: 100%;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: left;

    &::selection {
      background-color: rgba(83, 93, 180, 0.5);
    }
  }

  .hash {
    color: rgb(135, 141, 155);
    padding: 0rem 0rem 0rem 0.5rem;
  }

  &:hover,
  &:focus-within,
  &:focus-visible,
  &:active {
    .input {
      color: rgb(233, 236, 245);
    }
  }

  &:has(.textarea:disabled),
  &:has(.input:disabled) {
    background-color: rgb(46, 51, 62);

    .icon {

      path[id=Content],
      [id=Content] path {
        fill: rgb(135, 141, 155);
      }
    }
  }
}

.inputFieldError {
  align-items: center;

  .textarea,
  .input {
    color: rgb(135, 141, 155);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: left;

    &::selection {
      background-color: rgba(83, 93, 180, 0.5);
    }
  }

  &:hover,
  &:focus-within,
  &:focus-visible,
  &:focus-visible {

    .textarea,
    .input {
      color: rgb(233, 236, 245);
    }

    outline: 2px dashed rgb(204, 51, 61);
  }
}

.inputFieldHighlighted {
  align-items: center;

  .textarea,
  .input {
    color: rgb(135, 141, 155);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: left;

    &::selection {
      background-color: rgba(83, 93, 180, 0.5);
    }
  }

  &:hover,
  &:focus-within,
  &:focus-visible,
  &:focus-visible {

    .textarea,
    .input {
      color: rgb(233, 236, 245);
    }
  }
}

.inputFieldSelected {

  .textarea,
  .input {
    color: rgb(135, 141, 155);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: left;

    &::selection {
      background-color: rgba(83, 93, 180, 0.5);
    }
  }

  &:hover,
  &:focus-within,
  &:focus-visible,
  &:focus-visible {
    .input {
      color: rgb(233, 236, 245);
    }
  }
}

.inputFieldTextAreaSmall,
.inputFieldTextAreaMedium {
  .textarea {
    display: flex;
    width: -webkit-fill-available;
    width: -moz-available;
    height: -webkit-fill-available;
    height: -moz-available;
  }
}

.inputFieldTextMedium {
  &:focus-within {
    outline: 2px solid rgb(83, 93, 180);
    outline-offset: 0;
  }
}

.inputFieldCodeTextAreaMedium {
  padding: 0.25rem 0.5rem 0.5rem 0.5rem;

  .textarea,
  .input {
    color: rgb(135, 141, 155);
    height: 100%;
    font-size: 0.875rem;
    line-height: 1.50rem;
    overflow: hidden;
    resize: none;
    white-space: pre;
    overflow-x: auto;
  }

  &:has(.textarea:disabled),
  &:has(.input:disabled) {
    background-color: rgb(7, 7, 9);
  }
}