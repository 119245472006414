.resizeHandle {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 1rem;
  height: 1rem;
  cursor: se-resize;
  touch-action: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  user-select: none;
}
