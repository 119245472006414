.responseItemList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;

  .responseItem {
    display: flex;
    // width: 64rem;
    height: 1.5rem;
    align-self: stretch;
  }
  
}
