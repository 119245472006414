.tableRowListEditVariablesDefault {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(26, 28, 34);
  gap: 0.5rem;

  .tableRow {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.tableRowListInputVariableDefault {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(26, 28, 34);
  gap: 0.5rem;

  .tableRow {
    display: flex;
    width: unset;
    height: 2rem;
    align-self: stretch;
  }
  
}

.tableRowListKeyValueDefault {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(26, 28, 34);
  gap: 0.5rem;

  .tableRow {
    display: flex;
    width: unset;
    height: 2rem;
    align-self: stretch;
  }
  
}

.tableRowListKeyValueVariable {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(26, 28, 34);
  gap: 0.5rem;

  .tableRow {
    display: flex;
    width: unset;
    height: 2rem;
    align-self: stretch;
  }
  
}

.tableRowListTestVariableDefault {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(26, 28, 34);
  gap: 0.5rem;

  .tableRow {
    display: flex;
    width: unset;
    height: 2rem;
    align-self: stretch;
  }
  
}
