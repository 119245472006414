.previewBodyElement {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  width: 100vw;
  min-height: 100vh;
  height: fit-content;
}