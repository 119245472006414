.colourPreview {
  overflow: hidden;
  display: block;
  border-radius: 4px;
  background-color: rgb(195, 195, 195);
  outline: 2px solid rgb(7, 7, 9);
  outline-offset: 0;
  width: 2rem;
  height: 2rem;

}
