// MANUAL OVERRIDE START
.dropdownButtonRoot {
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  width: 100%; // MANUAL OVERRIDE
}
// MANUAL OVERRIDE END

.dropdownButtonDefaultDefaultCollapsedDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem; // MANUAL OVERRIDE
  border-radius: 4px;
  background-color: rgb(39, 43, 51);
  height: 2rem;
  width: 100%; // MANUAL OVERRIDE

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  &:active {
    background-color: rgb(65, 71, 85);
  
  }
  &:focus-visible {
    background-color: rgb(65, 71, 85);
    outline: 2px dashed rgb(83, 93, 180);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(65, 71, 85);
  
  }
}

.dropdownButtonDefaultDefaultExpandedDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem; // MANUAL OVERRIDE
  border-radius: 4px;
  background-color: rgb(39, 43, 51);
  height: 2rem;
  width: 100%; // MANUAL OVERRIDE

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  &:active {
    background-color: rgb(65, 71, 85);
  
  }
  &:focus-visible {
    background-color: rgb(65, 71, 85);
    outline: 2px dashed rgb(83, 93, 180);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(65, 71, 85);
  
  }
}

.dropdownButtonDefaultHighlightedCollapsedDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem; // MANUAL OVERRIDE
  border-radius: 4px;
  background-color: rgb(39, 43, 51);
  outline: 2px solid rgb(83, 93, 180);
  outline-offset: 0;
  height: 2rem;
  width: 100%; // MANUAL OVERRIDE

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  &:active {
    background-color: rgb(65, 71, 85);
  
  }
  &:focus-visible {
    background-color: rgb(65, 71, 85);
    outline: 2px dashed rgb(83, 93, 180);
  
  }
  &:hover {
    background-color: rgb(65, 71, 85);
  
  }
}

.dropdownButtonDefaultHighlightedExpandedDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem; // MANUAL OVERRIDE
  border-radius: 4px;
  background-color: rgb(39, 43, 51);
  outline: 2px solid rgb(83, 93, 180);
  outline-offset: 0;
  height: 2rem;
  width: 100%; // MANUAL OVERRIDE

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  &:active {
    background-color: rgb(65, 71, 85);
  
  }
  &:focus-visible {
    background-color: rgb(65, 71, 85);
    outline: 2px dashed rgb(83, 93, 180);
  
  }
  &:hover {
    background-color: rgb(65, 71, 85);
  
  }
}

.dropdownButtonIconTextDefaultCollapsedDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem; // MANUAL OVERRIDE
  border-radius: 4px;
  background-color: rgb(39, 43, 51);
  height: 2rem;
  width: 100%; // MANUAL OVERRIDE

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .icon1 {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  &:active {
    background-color: rgb(65, 71, 85);
  
  }
  &:focus-visible {
    background-color: rgb(65, 71, 85);
    outline: 2px dashed rgb(83, 93, 180);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(65, 71, 85);
  
  }
}

.dropdownButtonIconTextDefaultExpandedDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem; // MANUAL OVERRIDE
  border-radius: 4px;
  background-color: rgb(39, 43, 51);
  height: 2rem;
  width: 100%; // MANUAL OVERRIDE

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .icon1 {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  &:active {
    background-color: rgb(65, 71, 85);
  
  }
  &:focus-visible {
    background-color: rgb(65, 71, 85);
    outline: 2px dashed rgb(83, 93, 180);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(65, 71, 85);
  
  }
}

.dropdownButtonMenuIconDefaultCollapsedDefault {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
}

.dropdownButtonMethodDefaultCollapsedGreen {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem; // MANUAL OVERRIDE
  border-radius: 4px;
  background-color: rgba(58, 118, 77, 0.6000000238418579);
  outline: 1px solid rgb(83, 172, 112);
  outline-offset: 0;
  width: 5.75rem;
  height: 2rem;

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  &:active {
    background-color: rgba(67, 135, 89, 0.6000000238418579);
  
  }
  &:focus-visible {
    background-color: rgba(67, 135, 89, 0.6000000238418579);
    outline: 2px dashed rgb(39, 43, 51);
  
  }
  &:hover {
    background-color: rgba(67, 135, 89, 0.6000000238418579);
  
  }
}

.dropdownButtonMethodDefaultCollapsedPink {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem; // MANUAL OVERRIDE
  border-radius: 4px;
  background-color: rgba(106, 55, 120, 0.6000000238418579);
  outline: 1px solid rgb(155, 80, 175);
  outline-offset: 0;
  width: 5.75rem;
  height: 2rem;

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  &:active {
    background-color: rgba(123, 63, 138, 0.6000000238418579);
  
  }
  &:focus-visible {
    background-color: rgba(123, 63, 138, 0.6000000238418579);
    outline: 2px dashed rgb(39, 43, 51);
  
  }
  &:hover {
    background-color: rgba(123, 63, 138, 0.6000000238418579);
  
  }
}

.dropdownButtonMethodDefaultCollapsedPurple {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem; // MANUAL OVERRIDE
  border-radius: 4px;
  background-color: rgba(53, 60, 121, 0.6000000238418579);
  outline: 1px solid rgb(83, 93, 180);
  outline-offset: 0;
  width: 5.75rem;
  height: 2rem;

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  &:active {
    background-color: rgba(61, 69, 140, 0.6000000238418579);
  
  }
  &:focus-visible {
    background-color: rgba(61, 69, 140, 0.6000000238418579);
    outline: 2px dashed rgb(39, 43, 51);
  
  }
  &:hover {
    background-color: rgba(61, 69, 140, 0.6000000238418579);
  
  }
}

.dropdownButtonMethodDefaultCollapsedRed {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem; // MANUAL OVERRIDE
  border-radius: 4px;
  background-color: rgba(140, 35, 42, 0.6000000238418579);
  outline: 1px solid rgb(204, 51, 61);
  outline-offset: 0;
  width: 5.75rem;
  height: 2rem;

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  &:active {
    background-color: rgba(161, 40, 48, 0.6000000238418579);
  
  }
  &:focus-visible {
    background-color: rgba(161, 40, 48, 0.6000000238418579);
    outline: 2px dashed rgb(39, 43, 51);
  
  }
  &:hover {
    background-color: rgba(161, 40, 48, 0.6000000238418579);
  
  }
}

.dropdownButtonMethodDefaultCollapsedYellow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem; // MANUAL OVERRIDE
  border-radius: 4px;
  background-color: rgba(163, 120, 12, 0.6000000238418579);
  outline: 1px solid rgb(238, 176, 17);
  outline-offset: 0;
  width: 5.75rem;
  height: 2rem;

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  &:active {
    background-color: rgba(188, 139, 13, 0.6000000238418579);
  
  }
  &:focus-visible {
    background-color: rgba(188, 139, 13, 0.6000000238418579);
    outline: 2px dashed rgb(39, 43, 51);
  
  }
  &:hover {
    background-color: rgba(188, 139, 13, 0.6000000238418579);
  
  }
}

.dropdownButtonDefaultDefaultDisabledDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(63, 71, 86);
  height: unset; // MANUAL OVERRIDE
  width: 100%; // MANUAL OVERRIDE

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
}

.dropdownButtonDefaultErrorCollapsedDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(39, 43, 51);
  outline: 1px solid rgb(204, 51, 61);
  outline-offset: 0;
  height: 2rem;

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  &:hover {
    background-color: rgb(65, 71, 85);
  
  }
}

.dropdownButtonDefaultErrorExpandedDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(39, 43, 51);
  outline: 1px solid rgb(204, 51, 61);
  outline-offset: 0;
  height: 2rem;

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  &:hover {
    background-color: rgb(65, 71, 85);
  
  }
}

.dropdownButtonIconDefaultCollapsedDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.125rem 0.5rem 0.125rem;
  border-radius: 4px;
  background-color: rgb(39, 43, 51);
  outline: 1px solid rgb(26, 28, 34);
  outline-offset: 0;

  .icon1 {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .icon2 {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.125rem;
  }
  
  &:focus-visible {
    background-color: rgb(26, 28, 34);
    outline: 2px dashed rgb(83, 93, 180);
  
  }
  &:hover {
    background-color: rgb(26, 28, 34);
  
  }
}

.dropdownButtonIconDefaultExpandedDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.125rem 0.5rem 0.125rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);

  .icon1 {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .icon2 {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.125rem;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(83, 93, 180);
    outline-offset: 0;
  
  }
}

.dropdownButtonIconRowHoverCollapsedDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.125rem 0.5rem 0.125rem;
  border-radius: 4px;
  background-color: rgb(39, 43, 51);
  outline: 1px solid rgb(26, 28, 34);
  outline-offset: 0;

  .icon1 {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .icon2 {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.125rem;
  }
  
}

.dropdownButtonMenuIconDefaultExpandedDefault {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
}

.dropdownButtonMethodDefaultExpandedGreen {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem; // MANUAL OVERRIDE
  border-radius: 4px;
  background-color: rgba(67, 135, 89, 0.6000000238418579);
  outline: 1px solid rgb(83, 172, 112);
  outline-offset: 0;
  width: 5.75rem;
  height: 2rem;

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(39, 43, 51);
  
  }
}

.dropdownButtonMethodDefaultExpandedPink {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem; // MANUAL OVERRIDE
  border-radius: 4px;
  background-color: rgba(123, 63, 138, 0.6000000238418579);
  outline: 1px solid rgb(155, 80, 175);
  outline-offset: 0;
  width: 5.75rem;
  height: 2rem;

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(39, 43, 51);
  
  }
}

.dropdownButtonMethodDefaultExpandedPurple {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem; // MANUAL OVERRIDE
  border-radius: 4px;
  background-color: rgba(61, 69, 140, 0.6000000238418579);
  outline: 1px solid rgb(83, 93, 180);
  outline-offset: 0;
  width: 5.75rem;
  height: 2rem;

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(39, 43, 51);
  
  }
}

.dropdownButtonMethodDefaultExpandedRed {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem; // MANUAL OVERRIDE
  border-radius: 4px;
  background-color: rgba(161, 40, 48, 0.6000000238418579);
  outline: 1px solid rgb(204, 51, 61);
  outline-offset: 0;
  width: 5.75rem;
  height: 2rem;

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(39, 43, 51);
  
  }
}

.dropdownButtonMethodDefaultExpandedYellow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem; // MANUAL OVERRIDE
  border-radius: 4px;
  background-color: rgba(188, 139, 13, 0.6000000238418579);
  outline: 1px solid rgb(238, 176, 17);
  outline-offset: 0;
  width: 5.75rem;
  height: 2rem;

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(39, 43, 51);
  
  }
}

.dropdownButtonSelectableRowDefaultCollapsedDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(39, 43, 51);
  outline: 1px solid rgb(26, 28, 34);
  outline-offset: 0;
  height: 2rem;

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  &:focus-visible {
    background-color: rgb(26, 28, 34);
    outline: 2px dashed rgb(83, 93, 180);
  
  }
  &:hover {
    background-color: rgb(26, 28, 34);
    outline: 1px solid rgb(7, 7, 9);
  
  }
}

.dropdownButtonSelectableRowDefaultExpandedDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  outline: 1px solid rgb(26, 28, 34);
  outline-offset: 0;
  height: 2rem;

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(83, 93, 180);
  
  }
}

.dropdownButtonSelectableRowRowHoverCollapsedDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(39, 43, 51);
  outline: 1px solid rgb(7, 7, 9);
  outline-offset: 0;
  height: 2rem;

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
}
