.layerLabelItemCollapsedDefaultDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 0.75rem; // MANUAL OVERRIDE
  background-color: rgb(26, 28, 34);
  height: 2rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem; // MANUAL OVERRIDE
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
    // margin-left: 0.5rem; // MANUAL OVERRIDE
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    width: unset;
    height: unset;
    // align-self: stretch; // MANUAL OVERRIDE
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.layerLabelItemCollapsedDefaultHidden {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 0.75rem; // MANUAL OVERRIDE
  background-color: rgb(26, 28, 34);
  height: 2rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem; // MANUAL OVERRIDE
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
    // margin-left: 0.5rem; // MANUAL OVERRIDE
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    width: unset;
    height: unset;
    // align-self: stretch; // MANUAL OVERRIDE
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .icon1 {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
}

.layerLabelItemCollapsedEditableSelectedDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 0.75rem; // MANUAL OVERRIDE
  background-color: rgb(65, 71, 85);
  height: 2rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem; // MANUAL OVERRIDE
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
    // margin-left: 0.5rem; // MANUAL OVERRIDE
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .textInput {
    display: flex;
    width: unset;
    height: 1.5rem;
    flex: 1 1 auto;
    margin-left: 0.25rem;
    max-width: 10.6rem; // MANUAL OVERRIDE
  }
  
}

.layerLabelItemCollapsedEditableSelectedHidden {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 0.75rem; // MANUAL OVERRIDE
  background-color: rgb(65, 71, 85);
  height: 2rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem; // MANUAL OVERRIDE
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
    // margin-left: 0.5rem; // MANUAL OVERRIDE
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .textInput {
    display: flex;
    width: unset;
    height: 1.5rem;
    flex: 1 1 auto;
    margin-left: 0.25rem;
    max-width: 10.6rem; // MANUAL OVERRIDE
  }
  
  .icon1 {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
  }
  
}

.layerLabelItemCollapsedSelectedDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 0.75rem; // MANUAL OVERRIDE
  background-color: rgb(65, 71, 85);
  height: 2rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem; // MANUAL OVERRIDE
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
    // margin-left: 0.5rem; // MANUAL OVERRIDE
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    width: unset;
    height: unset;
    // align-self: stretch; // MANUAL OVERRIDE
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.layerLabelItemCollapsedSelectedHidden {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 0.75rem; // MANUAL OVERRIDE
  background-color: rgb(65, 71, 85);
  height: 2rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem; // MANUAL OVERRIDE
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
    // margin-left: 0.5rem; // MANUAL OVERRIDE
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    width: unset;
    height: unset;
    // align-self: stretch; // MANUAL OVERRIDE
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .icon1 {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
}

.layerLabelItemDefaultDefaultDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 2.25rem; // MANUAL OVERRIDE
  background-color: rgb(26, 28, 34);
  height: 2rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    width: unset;
    height: unset;
    // align-self: stretch; // MANUAL OVERRIDE
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.layerLabelItemDefaultDefaultHidden {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 2.25rem; // MANUAL OVERRIDE
  background-color: rgb(26, 28, 34);
  height: 2rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    width: unset;
    height: unset;
    // align-self: stretch; // MANUAL OVERRIDE
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .icon1 {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  &:hover {
    height: 2.1875rem;
  
  }
}

.layerLabelItemDefaultEditableSelectedDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 2.25rem; // MANUAL OVERRIDE
  background-color: rgb(65, 71, 85);
  height: 2rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .textInput {
    display: flex;
    width: unset;
    height: 1.5rem;
    flex: 1 1 auto;
    margin-left: 0.25rem;
    max-width: 10.6rem; // MANUAL OVERRIDE
  }
  
}

.layerLabelItemDefaultEditableSelectedHidden {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 2.25rem; // MANUAL OVERRIDE
  background-color: rgb(65, 71, 85);
  height: 2rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .textInput {
    display: flex;
    width: unset;
    height: 1.5rem;
    flex: 1 1 auto;
    margin-left: 0.25rem;
    max-width: 10.6rem; // MANUAL OVERRIDE
  }
  
  .icon1 {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
  }
  
}

.layerLabelItemDefaultSelectedDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 2.25rem; // MANUAL OVERRIDE
  background-color: rgb(65, 71, 85);
  height: 2rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    width: unset;
    height: unset;
    // align-self: stretch; // MANUAL OVERRIDE
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.layerLabelItemDefaultSelectedHidden {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 2.25rem; // MANUAL OVERRIDE
  background-color: rgb(65, 71, 85);
  height: 2rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    width: unset;
    height: unset;
    // align-self: stretch; // MANUAL OVERRIDE
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .icon1 {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
}

.layerLabelItemExpandedDefaultDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 0.75rem; // MANUAL OVERRIDE
  background-color: rgb(26, 28, 34);
  height: 2rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem; // MANUAL OVERRIDE
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
    // margin-left: 0.5rem; // MANUAL OVERRIDE
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    width: unset;
    height: unset;
    // align-self: stretch; // MANUAL OVERRIDE
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.layerLabelItemExpandedDefaultHidden {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 0.75rem; // MANUAL OVERRIDE
  background-color: rgb(26, 28, 34);
  height: 2rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem; // MANUAL OVERRIDE
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
    // margin-left: 0.5rem; // MANUAL OVERRIDE
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    width: unset;
    height: unset;
    // align-self: stretch; // MANUAL OVERRIDE
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .icon1 {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
}

.layerLabelItemExpandedEditableSelectedDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 0.75rem; // MANUAL OVERRIDE
  background-color: rgb(65, 71, 85);
  height: 2rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem; // MANUAL OVERRIDE
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
    // margin-left: 0.5rem; // MANUAL OVERRIDE
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .textInput {
    display: flex;
    width: unset;
    height: 1.5rem;
    flex: 1 1 auto;
    margin-left: 0.25rem;
    max-width: 10.6rem; // MANUAL OVERRIDE
  }
  
}

.layerLabelItemExpandedEditableSelectedHidden {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 0.75rem; // MANUAL OVERRIDE
  background-color: rgb(65, 71, 85);
  height: 2rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem; // MANUAL OVERRIDE
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
    // margin-left: 0.5rem; // MANUAL OVERRIDE
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .textInput {
    display: flex;
    width: unset;
    height: 1.5rem;
    flex: 1 1 auto;
    margin-left: 0.25rem;
    max-width: 10.6rem; // MANUAL OVERRIDE
  }
  
  .icon1 {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
  }
  
}

.layerLabelItemExpandedSelectedDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 0.75rem; // MANUAL OVERRIDE
  background-color: rgb(65, 71, 85);
  height: 2rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem; // MANUAL OVERRIDE
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
    // margin-left: 0.5rem; // MANUAL OVERRIDE
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    width: unset;
    height: unset;
    // align-self: stretch; // MANUAL OVERRIDE
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.layerLabelItemExpandedSelectedHidden {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 0 0.75rem; // MANUAL OVERRIDE
  background-color: rgb(65, 71, 85);
  height: 2rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem; // MANUAL OVERRIDE
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
    // margin-left: 0.5rem; // MANUAL OVERRIDE
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    width: unset;
    height: unset;
    // align-self: stretch; // MANUAL OVERRIDE
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .icon1 {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
}

// MANUAL OVERRIDE STARTS
.contentWrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: max-content;
  height: 100%;
  position: relative;

  .content {
    padding-right: 0.75rem;
  }
}

.dropZoneParentBottom {
  bottom: -0.25rem;
  width: 100%;
  overflow: hidden;
}

.dropZoneParentBottomInner {
  bottom: 0rem;
  padding-left: 1.5rem;
  width: 100%;
  overflow: hidden;
}

.disabled {
  filter: brightness(0.5);
}
// MANUAL OVERRIDE ENDS