.sectionLabelMainSectionTextIconRight {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 0rem 0rem 0.75rem;
  background-color: rgb(26, 28, 34);
  height: 3rem;

  .textContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .text {
    display: flex;
  }
  
  .button {
    display: flex;
    width: 3rem;
    height: 3rem;
    margin-left: 4.25rem;
  }
  
}

.sectionLabelMainSectionTextNoIcon {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0rem 0rem 0.75rem;
  background-color: rgb(26, 28, 34);
  height: 3rem;

  .text {
    display: flex;
  }
  
}

.sectionLabelSubsectionTextIconLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0rem 0rem 0.5rem;
  background-color: rgb(26, 28, 34);
  height: 2.5rem;

  .button {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.25rem;
  }
  
}

.sectionLabelSubsectionTextIconRight {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 0.75rem 0rem 0.75rem;
  background-color: rgb(26, 28, 34);
  height: 2.5rem;

  .text {
    display: flex;
  }
  
  .button {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 4.25rem;
  }
  
}

.sectionLabelSubsectionTextNoIcon {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0rem 0rem 0.75rem;
  background-color: rgb(26, 28, 34);
  height: 2.5rem;

  .text {
    display: flex;
  }
  
}
