.responseSectionCollapsed {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(26, 28, 34);
  width: 48rem;

  .expandedSectionHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.responseSectionExpanded {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(26, 28, 34);
  width: 48rem;
  height: 36.5rem;

  .expandedSectionHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .text {
    display: flex;
    margin-top: 0.5rem;
  }
  
  .responseItemContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    background-color: rgb(17, 18, 22);
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-top: 0.5rem;
  }
  
  .responseItemList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
