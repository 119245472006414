.replaceButtonDefaultDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .button {
    display: flex;
    width: unset;
    height: 2rem;
    align-self: stretch;
  }
  
}

.replaceButtonDefaultTextOnly {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .button {
    display: flex;
    width: unset;
    height: 2rem;
    align-self: stretch;
  }
  
}

.replaceButtonUploadedImage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .propertyItem {
    display: flex;
    width: unset;
    height: 2rem;
    align-self: stretch;
  }
  
}

.replaceButtonUploadedUpdateVariable {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  background-color: rgb(39, 43, 51);
  height: 2rem;

  .propertyItem {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
}

.replaceButtonUploadedVariableImage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .propertyItem {
    display: flex;
    width: unset;
    height: 2rem;
    align-self: stretch;
  }
  
}
