.settingsRowList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  background-color: rgb(26, 28, 34);
  gap: 0.75rem;

  .settingsRow {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
