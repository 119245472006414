.accordionHeaderCollapsed {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0rem;
  border-radius: 4px;
  background-color: rgb(39, 43, 51);

  .textDivider {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.3125rem;
  }
  
}

.accordionHeaderExpanded {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0rem;
  border-radius: 4px;
  background-color: rgb(39, 43, 51);

  .textDivider {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.3125rem;
  }
  
}
