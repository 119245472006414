.textCardList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;

  .textCard {
    display: flex;
    width: unset;
    align-self: stretch;
    min-height: 3.5rem; // MANUAL OVERRIDE
  }
  
}
