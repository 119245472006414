.updateArrow {
  display: block;
  background-color: rgb(255, 255, 255);
  width: 0.46024763584136963rem;
  height: 1.648441195487976rem;

  path[id=Line970Stroke], [id=Line970Stroke] path {
    display: block;
    fill: rgb(198, 116, 184);
  }
  
}
