.dividerHorizontalNeutral1900 {
  overflow: hidden;
  display: block;
  background-color: rgb(7, 7, 9);
  width: 5rem;
  height: 0.0625rem;
  min-height: 0.0625rem; // MANUAL OVERRIDE
}

.dividerHorizontalNeutralBG2 {
  overflow: hidden;
  display: block;
  background-color: rgb(46, 51, 62);
  width: 5rem;
  height: 0.0625rem;
  min-height: 0.0625rem; // MANUAL OVERRIDE
}

.dividerHorizontalNeutralBG3 {
  overflow: hidden;
  display: block;
  background-color: rgb(63, 71, 86);
  width: 5rem;
  height: 0.0625rem;
  min-height: 0.0625rem; // MANUAL OVERRIDE

}

.dividerVerticalNeutral1900 {
  overflow: hidden;
  display: block;
  background-color: rgb(7, 7, 9);
  width: 0.0625rem;
  min-width: 0.0625rem; // MANUAL OVERRIDE
}
