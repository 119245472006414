.errorBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(75, 19, 23);
  height: 1.5rem;

  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
}
