.variableLayerItemDefaultCollapsed {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.75rem 0rem 0.75rem;
  background-color: rgb(17, 18, 22);
  height: 2rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
  }

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
  }

  .name {
    display: flex;
    margin-left: 0.25rem;
  }

  .variableType {
    display: flex;
    margin-left: 0.25rem;
  }

  &:hover {
    background-color: rgb(39, 43, 51);

  }
}

.variableLayerItemDefaultDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0rem 0rem 2rem;
  background-color: rgb(17, 18, 22);
  height: 2rem;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }

  .name {
    display: flex;
    margin-left: 0.25rem;
  }

  .variableType {
    display: flex;
    margin-left: 0.25rem;
  }

  &:hover {
    background-color: rgb(39, 43, 51);

  }
}

.variableLayerItemDefaultExpanded {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.75rem 0rem 0.75rem;
  background-color: rgb(17, 18, 22);
  height: 2rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
  }

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
  }

  .name {
    display: flex;
    margin-left: 0.25rem;
  }

  .variableType {
    display: flex;
    margin-left: 0.25rem;
  }

  &:hover {
    background-color: rgb(39, 43, 51);

  }
}

.variableLayerItemSelectedCollapsed {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.75rem 0rem 0.75rem;
  background-color: rgb(65, 71, 85);
  height: 2rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
  }

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
  }

  .name {
    display: flex;
    margin-left: 0.25rem;
  }

  .variableType {
    display: flex;
    margin-left: 0.25rem;
  }

}

.variableLayerItemSelectedDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0rem 0rem 2rem;
  background-color: rgb(65, 71, 85);
  height: 2rem;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }

  .name {
    display: flex;
    margin-left: 0.25rem;
  }

  .variableType {
    display: flex;
    margin-left: 0.25rem;
  }

}

.variableLayerItemSelectedExpanded {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.75rem 0rem 0.75rem;
  background-color: rgb(65, 71, 85);
  height: 2rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
  }

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
  }

  .name {
    display: flex;
    margin-left: 0.25rem;
  }

  .variableType {
    display: flex;
    margin-left: 0.25rem;
  }

}