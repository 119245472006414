.setVariableMenuDefaultDefault {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  outline: 1px solid rgb(46, 51, 62);
  outline-offset: 0;
  box-shadow: 3px 4px 12px rgba(0, 0, 0, 0.5);
  width: 23.5rem;
  height: 34.5rem;

  .menuHeader {
    display: flex;
    width: unset;
    height: 2.25rem;
    align-self: stretch;
  }
  
  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  .menuContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    overflow: hidden; // MANUAL OVERRIDE
  }
  
  .variableLayerContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.25rem 0.25rem 0.25rem 0.25rem;
    background-color: rgb(17, 18, 22);
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    overflow: auto; // MANUAL OVERRIDE
  }
  
  .variableLayerItemList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .buttonContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .buttonGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  
  .secondaryButton {
    display: flex;
  }
  
  .primaryButton {
    display: flex;
    margin-left: 0.5rem;
  }
  
}

.setVariableMenuDefaultEmpty {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  outline: 1px solid rgb(46, 51, 62);
  outline-offset: 0;
  box-shadow: 3px 4px 12px rgba(0, 0, 0, 0.5);
  width: 23.5rem;
  height: 34.5rem;

  .menuHeader {
    display: flex;
    width: unset;
    height: 2.25rem;
    align-self: stretch;
  }
  
  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  .menuContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    overflow: hidden; // MANUAL OVERRIDE
  }
  
  .variableLayerContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    background-color: rgb(17, 18, 22);
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    overflow: auto; // MANUAL OVERRIDE
  }
  
  .text {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .buttonContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .buttonGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  
  .secondaryButton {
    display: flex;
  }
  
  .primaryButton {
    display: flex;
    margin-left: 0.5rem;
  }
  
}

.setVariableMenuRemoveableDefault {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  outline: 1px solid rgb(46, 51, 62);
  outline-offset: 0;
  box-shadow: 3px 4px 12px rgba(0, 0, 0, 0.5);
  width: 23.5rem;
  height: 34.5rem;

  .menuHeader {
    display: flex;
    width: unset;
    height: 2.25rem;
    align-self: stretch;
  }
  
  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  .menuContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    overflow: hidden; // MANUAL OVERRIDE
  }
  
  .variableLayerContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.25rem 0.25rem 0.25rem 0.25rem;
    background-color: rgb(17, 18, 22);
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    overflow: auto; // MANUAL OVERRIDE
  }
  
  .variableLayerItemList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .buttonContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .button {
    display: flex;
    width: 4rem;
    height: 2rem;
  }
  
  .buttonGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-left: 0.75rem;
  }
  
  .secondaryButton {
    display: flex;
  }
  
  .primaryButton {
    display: flex;
    margin-left: 0.5rem;
  }
  
}
