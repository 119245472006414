.variableCardListLarge {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap; // MANUAL OVERRIDE
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;

  .variableCard {
    display: flex;
    width: unset;
    height: 3.5rem;
    align-self: stretch;
  }
  
}

.variableCardListSmall {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  .variableCard {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
