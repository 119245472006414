.headerBlockDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(7, 7, 9);

  .errorContent {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    width: 41.875rem;
    height: 1.5rem;
  }
  
  .keyValueTable {
    display: flex;
    width: 41.875rem;
    margin-top: 0.5rem;
  }
  
}

.headerBlockError {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(7, 7, 9);

  .errorContent {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 41.875rem;
    height: 1.5rem;
  }
  
  .errorBar {
    display: flex;
    width: unset;
    height: 1.5rem;
    flex: 1 1 auto;
  }
  
  .keyValueTable {
    display: flex;
    width: 41.875rem;
    margin-top: 0.5rem;
  }
  
}
