html {
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

div, textarea {
  scrollbar-color: rgb(135, 141, 155) rgb(26, 28, 34); /* Firefox only */
  scrollbar-width: thin; /* Firefox only */
}

div::-webkit-scrollbar, textarea::-webkit-scrollbar {
  width: 0.375rem; /* Mostly for vertical scrollbars */
  height: 0.375rem; /* Mostly for horizontal scrollbars */
}

div::-webkit-scrollbar-thumb, textarea::-webkit-scrollbar-thumb { /* Foreground */
  background: rgb(135, 141, 155);
  border-radius: 0.375rem;
}

div::-webkit-scrollbar-track, textarea::-webkit-scrollbar-track { /* Background */
  background: rgb(26, 28, 34);
}
