.expandedVariableResponses {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  // MANUAL OVERRIDE START
  outline: 1px solid rgb(46, 51, 62);
  outline-offset: 0;
  // MANUAL OVERRIDE END

  .headerContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .menuHeader {
    display: flex;
    width: unset;
    height: 2.5rem;
    align-self: stretch;
  }
  
  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  .expandedViewContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    background-color: rgb(26, 28, 34);
    // width: 51.5rem; MANUAL OVERRIDE
    height: unset;
    flex: 1 1 auto;
  }
  
  .variableSection {
    display: flex;
    width: 48rem;
    height: unset;
    flex: 1 1 auto;
  }
  
  .responseSection {
    display: flex;
    width: 48rem;
    height: unset;
    flex: 1 1 auto;
    margin-top: 1.5rem;
  }
  
}

// MANUAL OVERRIDE START
.customText {
  color: white;
}
// MANUAL OVERRIDE END
