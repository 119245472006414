.tableRowBooleanEditVariablesDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.125rem 0.5rem 0.125rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);

  .scopeName {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 12.0625rem; // MANUAL OVERRIDE
  }

  .dropdownButton {
    display: flex;
    height: 2rem; // MANUAL OVERRIDE
    width: 2.375rem; // MANUAL OVERRIDE
  }

  .nameInput {
    display: flex;
    width: 8.1875rem;
    height: 2rem;
    margin-left: 1.5rem;
  }

  .response {
    display: flex;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
    width: 13.0625rem; // MANUAL OVERRIDE
    margin-left: 0.75rem;
  }

  .valueType {
    display: flex;
    width: 4.3125rem;
    margin-left: 0.75rem;
  }

  .dropdownButton1 {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 0 0 auto; // MANUAL OVERRIDE
    width: 13.0625rem; // MANUAL OVERRIDE
    height: 2rem; // MANUAL OVERRIDE
    margin-left: 0.75rem;
  }

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.75rem;
  }

  &:hover {
    background-color: rgb(39, 43, 51);

  }
}

.tableRowBooleanEditVariablesselected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.125rem 0.5rem 0.125rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(65, 71, 85);

  .scopeName {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 12.0625rem; // MANUAL OVERRIDE
  }

  .dropdownButton {
    display: flex;
    height: 2rem; // MANUAL OVERRIDE
    width: 2.375rem; // MANUAL OVERRIDE
  }

  .nameInput {
    display: flex;
    width: 8.1875rem;
    height: 2rem;
    margin-left: 1.5rem;
  }

  .response {
    display: flex;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
    width: 13.0625rem; // MANUAL OVERRIDE
    margin-left: 0.75rem;
  }

  .valueType {
    display: flex;
    width: 4.3125rem;
    margin-left: 0.75rem;
  }

  .dropdownButton1 {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 0 0 auto; // MANUAL OVERRIDE
    width: 13.0625rem; // MANUAL OVERRIDE
    height: 2rem; // MANUAL OVERRIDE
    margin-left: 0.75rem;
  }

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.75rem;
  }

}

.tableRowBooleanInputVariableDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(26, 28, 34);
  height: 2rem;

  .variableNameInput {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
  }

  .dropdownButton {
    display: flex;
    width: 7.25rem;
    height: unset;
    align-self: stretch;
    margin-left: 0.75rem;
  }

  .defaultValue {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 0.75rem;
  }

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.75rem;
  }

}

.tableRowBooleanTestResponseDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(26, 28, 34);
  height: 2rem;

  .variableName {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }

  .variableType {
    display: flex;
    width: 6rem;
    margin-left: 0.75rem;
  }

  .dropdownButton {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 0.75rem;
  }

}

.tableRowCollectionObjectEditVariablesDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.125rem 0.5rem 0.125rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);

  .scopeName {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 12.0625rem; // MANUAL OVERRIDE
  }

  .dropdownButton {
    display: flex;
    height: 2rem; // MANUAL OVERRIDE
    width: 2.375rem; // MANUAL OVERRIDE
  }

  .nameInput {
    display: flex;
    width: 8.1875rem;
    height: 2rem;
    margin-left: 1.5rem;
  }

  .response {
    display: flex;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
    width: 13.0625rem; // MANUAL OVERRIDE
    margin-left: 0.75rem;
  }

  .valueType {
    display: flex;
    width: 4.3125rem;
    margin-left: 0.75rem;
  }

  .valueInput {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 0 0 auto; // MANUAL OVERRIDE
    width: 13.0625rem; // MANUAL OVERRIDE
    margin-left: 0.75rem;
  }

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.75rem;
  }

}

.tableRowDefaultEditVariablesDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.125rem 0.5rem 0.125rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);

  .scopeName {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 12.0625rem; // MANUAL OVERRIDE
  }

  .dropdownButton {
    display: flex;
    height: 2rem; // MANUAL OVERRIDE
    width: 2.375rem; // MANUAL OVERRIDE
  }

  .nameInput {
    display: flex;
    width: 8.1875rem;
    height: 2rem;
    margin-left: 1.5rem;
  }

  .response {
    display: flex;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
    width: 13.0625rem; // MANUAL OVERRIDE
    margin-left: 0.75rem;
  }

  .valueType {
    display: flex;
    width: 4.3125rem;
    margin-left: 0.75rem;
  }

  .valueInput {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 0 0 auto; // MANUAL OVERRIDE
    width: 13.0625rem; // MANUAL OVERRIDE
    margin-left: 0.75rem;
  }

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.75rem;
  }

  &:hover {
    background-color: rgb(39, 43, 51);

  }
}

.tableRowDefaultEditVariablesselected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.125rem 0.5rem 0.125rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(65, 71, 85);

  .scopeName {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 12.0625rem; // MANUAL OVERRIDE
  }

  .dropdownButton {
    display: flex;
    height: 2rem; // MANUAL OVERRIDE
    width: 2.375rem; // MANUAL OVERRIDE
  }

  .nameInput {
    display: flex;
    width: 8.1875rem;
    height: 2rem;
    margin-left: 1.5rem;
  }

  .response {
    display: flex;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
    width: 13.0625rem; // MANUAL OVERRIDE
    margin-left: 0.75rem;
  }

  .valueType {
    display: flex;
    width: 4.3125rem;
    margin-left: 0.75rem;
  }

  .valueInput {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 0 0 auto; // MANUAL OVERRIDE
    width: 13.0625rem; // MANUAL OVERRIDE
    margin-left: 0.75rem;
  }

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.75rem;
  }

}

.tableRowDefaultInputVariableDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(26, 28, 34);
  height: 2rem;

  .variableNameInput {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
  }

  .dropdownButton {
    display: flex;
    width: 11.25rem;
    height: unset;
    align-self: stretch;
    margin-left: 0.75rem;
  }

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.75rem;
  }

}

.tableRowDefaultKeyValueDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(26, 28, 34);
  height: 2rem;

  .keyInput {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
  }

  .valueInput {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.75rem;
  }

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.75rem;
  }

}

.tableRowDefaultTestResponseDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(26, 28, 34);
  height: 2rem;

  .variableName {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }

  .variableType {
    display: flex;
    width: 6rem;
    margin-left: 0.75rem;
  }

  .inputField {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.75rem;
  }

}

.tableRowVariableKeyValueDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(26, 28, 34);
  height: 2rem;

  .keyInput {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
  }

  .variableValueInput {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 0.75rem;
  }

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.75rem;
  }

}