.testResponsePage {
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(7, 7, 9);
  width: 64rem;

  .pageContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .rightContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
  }
  
  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem 2rem 0rem 2rem;
    width: unset;
    align-self: stretch;
  }
  
  .breadcrumbContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 56.9375rem;
  }
  
  .breadcrumbItemList {
    display: flex;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0.25rem 2rem 2rem 2rem;
    width: unset;
    align-self: stretch;
  }
  
  .headerContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 56.9375rem;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .textInput {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .button {
    display: flex;
    margin-left: 0.5rem;
  }
  
  .errorContent {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    height: 1.5rem;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .testResponseContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1rem;
  }
  
  .testVariableBlock {
    display: flex;
    width: 56.9375rem;
  }
  
  .responseStructureBlock {
    display: flex;
    width: 56.9375rem;
    margin-top: 1rem;
  }
  
  .testVariableBlock1 {
    display: flex;
    width: 56.9375rem;
    margin-top: 1rem;
  }
  
}
