.statusChipDefault {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 100px;
  background-color: rgb(17, 18, 22);
  width: 6rem;
  height: 2rem;

  .text {
    display: flex;
  }
  
}

.statusChipError {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 100px;
  background-color: rgb(17, 18, 22);
  width: 6rem;
  height: 2rem;

  .text {
    display: flex;
  }
  
}

.statusChipSuccess {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 100px;
  background-color: rgb(17, 18, 22);
  width: 6rem;
  height: 2rem;

  .text {
    display: flex;
  }
  
}
