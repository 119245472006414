.settingsRowToggle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(26, 28, 34);

  .toggleRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  .rowLabel {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .toggleItemList {
    display: flex;
    width: 13.5rem;
    margin-left: 0.5rem;
  }
  
}

.settingsRowVariable {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(26, 28, 34);

  .replaceRow {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
