.textInputDefaultMedium {
  // MANUAL OVERRIDE
  display: flex;
  flex-direction: column;
  justify-content: center; // MANUAL OVERRIDE
  align-items: flex-start;
  padding: 0.125rem 0.125rem 0.125rem 0.125rem; // MANUAL OVERRIDE
  height: 1.5rem;
  box-sizing: border-box; // MANUAL OVERRIDE

  .input {
    display: flex;
    width: unset;
    align-self: stretch;
    // MANUAL OVERRIDE STARTS
    background-color: rgb(7, 7, 9);
    color: rgb(135, 141, 155);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    padding-top: 0;
    padding-bottom: 0;

    &::selection {
      background-color: rgba(83, 93, 180, 0.5);
    }

    // MANUAL OVERRIDE ENDS
  }

  // MANUAL OVERRIDE STARTS
  // &:active {
  //   background-color: rgb(7, 7, 9);
  //   outline: 2px solid rgb(83, 93, 180);
  //   outline-offset: 0;

  // }

  &:hover {
    .input {
      color: rgb(233, 236, 245);
    }
  }

  &:focus-within {
    .input {
      outline: 0.125rem solid rgb(83, 93, 180);
      outline-offset: 0;
      color: rgb(233, 236, 245);
    }
  }

  // MANUAL OVERRIDE ENDS
}

.textInputSelectedMedium {
  // MANUAL OVERRIDE
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.125rem 0.25rem 0.125rem 0.25rem;
  height: 1.5rem;

  .input {
    display: flex;
    width: unset;
    align-self: stretch;
  }

}

.textInputDefaultExtraLarge {
  // MANUAL OVERRIDE
  display: flex;
  flex-direction: column;
  justify-content: center; // MANUAL OVERRIDE
  align-items: flex-start;
  padding: 0.125rem 0.125rem 0.125rem 0.125rem; // MANUAL OVERRIDE
  height: 2rem;
  box-sizing: border-box; // MANUAL OVERRIDE

  .input {
    display: flex;
    width: unset;
    align-self: stretch;
    // MANUAL OVERRIDE STARTS
    background-color: rgb(7, 7, 9);
    color: rgb(233, 236, 245);
    font-family: 'Inter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    padding-top: 0;
    padding-bottom: 0;

    &::selection {
      background-color: rgba(83, 93, 180, 0.5);
    }

    // MANUAL OVERRIDE ENDS
  }

  // MANUAL OVERRIDE STARTS
  // &:active {
  //   background-color: rgb(7, 7, 9);
  //   outline: 2px solid rgb(83, 93, 180);
  //   outline-offset: 0;

  // }

  &:hover {
    .input {
      color: rgb(233, 236, 245);
    }
  }

  &:focus-within {
    .input {
      outline: 0.125rem solid rgb(83, 93, 180);
      outline-offset: 0;
      color: rgb(233, 236, 245);
    }
  }

  // MANUAL OVERRIDE ENDS
}

.textInputDefaultExtraLarge {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.125rem 0rem 0.125rem 0rem;

  .input {
    display: flex;
  }

  &:hover {
    background-color: rgb(7, 7, 9);
    border: 1px solid rgb(63, 71, 86);
    outline-offset: 0;

  }
}

.textInputSelectedExtraLarge {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.125rem 0rem 0.125rem 0rem;
  background-color: rgb(7, 7, 9);
  border: 2px solid rgb(83, 93, 180);
  outline-offset: 0;

  .input {
    display: flex;
  }

}