.stylePanel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0rem 0rem 0rem 0rem; // MANUAL CHANGE
  background-color: rgb(26, 28, 34);
  width: 20rem;
  overflow: auto; // MANUAL CHANGE
  scrollbar-gutter: stable;

  .sizePanel {
    display: flex;
    width: 20rem;
  }
  
  .paddingMarginsPanel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20rem;
  }
  
  .panelHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .panelContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    align-self: stretch;
  }
  
  .paddingMarginGrid {
    display: flex;
    width: 18.5rem;
    height: 9rem;
  }
  
  .layoutPanel {
    display: flex;
    width: 20rem;
  }
  
  .typographyPanel {
    display: flex;
    width: 20rem;
  }
  
  .backgroundPanel {
    display: flex;
    width: 20rem;
  }
  
  .borderPanel {
    display: flex;
    width: 20rem;
  }
  
  .cornersPanel {
    display: flex;
    width: 20rem;
  }
  
  .styleEffectsPanel {
    display: flex;
    width: 20rem;
  }
  
}
