.logicVariableTableEmpty {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(26, 28, 34);

  .emptyStateInfo {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.logicVariableTableFilled {
  overflow: visible; // MANUAL OVERRIDE
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  outline: 1px solid rgb(39, 43, 51);
  outline-offset: 0;

  .headerContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0rem 2.5rem 0rem 0.75rem;
    background-color: rgb(39, 43, 51);
    width: unset;
    height: 2rem;
    align-self: stretch;
  }
  
  .scopeNameContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 12.0625rem; // MANUAL OVERRIDE
  }
  
  .scope {
    display: flex;
  }
  
  .name {
    display: flex;
    width: 8.1875rem;
    margin-left: 1.1875rem;
  }
  
  .response {
    display: flex;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
    width: 13.0625rem; // MANUAL OVERRIDE
    margin-left: 0.75rem;
  }
  
  .type {
    display: flex;
    width: 4.3125rem;
    margin-left: 0.75rem;
  }
  
  .defaultValue {
    display: flex;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
    width: 13.0625rem; // MANUAL OVERRIDE
    margin-left: 0.75rem;
  }
  
  .tableContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.25rem 0.25rem 0.25rem 0.25rem;
    width: unset;
    align-self: stretch;
  }
  
  .tableRowList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
