.logicSettingsPanel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; // MANUAL OVERRIDE
  padding: 0rem 0rem 0rem 0rem; // MANUAL OVERRIDE
  background-color: rgb(26, 28, 34);
  width: 20rem;
  box-sizing: border-box; // MANUAL OVERRIDE
  height: 100%; // MANUAL OVERRIDE

  .headerContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0.75rem 0rem 0.75rem;
    width: unset;
    align-self: stretch;
  }
  
  .logicSettingsHeader {
    display: flex;
    width: unset;
    height: 2.5rem; // MANUAL OVERRIDE
    align-self: stretch;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    overflow: auto;
    height: 100%;
  }

  .selectedContainer {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .actionPanel {
    display: flex;
    width: 20rem;
  }
  
  .valuesPanel {
    display: flex;
    width: 20rem;
  }
  
  .variableResponsePanel {
    display: flex;
  }
  
}
