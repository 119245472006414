.colourRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .colour {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .colourPreview {
    display: block;
    width: 2rem;
    height: 2rem;
    margin-left: 0.5rem;
  }
  
  .hexCodeField {
    display: flex;
    width: 3rem; // MANUAL OVERRIDE
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .opacityField {
    display: flex;
    width: 3rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
    .inputField { // MANUAL OVERRIDE
      width: 3rem;
    }
  }
  
}
