.canvasElement {
  display: flex;
}

.fillWidth {
  width: -webkit-fill-available;
  width: -moz-available;
}

.fillHeight {
  height: -webkit-fill-available;
  height: -moz-available;
}
