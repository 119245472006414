.logicBuilderPanelCollapsed {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
   // MANUAL OVERRIDE START
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(26, 28, 34, 0.9);
  backdrop-filter: blur(25px);
   // MANUAL OVERRIDE END

  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  .logicBuilderContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  // MANUAL OVERRIDE START
  .logicBuilderContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  // MANUAL OVERRIDE END
  
  .logicBuilderHeader {
    display: flex;
    width: unset;
    height: 3rem;
    align-self: stretch;
  }
  
  .divider1 {
    display: block;
    width: 0.0625rem;
    height: unset;
    align-self: stretch;
  }
  
}

.logicBuilderPanelExpanded {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
   // MANUAL OVERRIDE START
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(26, 28, 34, 0.9);
  backdrop-filter: blur(25px);
   // MANUAL OVERRIDE END

  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  .logicBuilderContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .logicBuilderContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .logicBuilderHeader {
    display: flex;
    width: unset;
    height: 3rem;
    align-self: stretch;
  }
  
  .logicAreaContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .logicTabList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .actionContainer {
    display: block;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .divider1 {
    display: block;
    width: 0.0625rem;
    height: unset;
    align-self: stretch;
  }
  
}
