.breadcrumbItemDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .text {
    display: flex;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
  }
  
}

.breadcrumbItemSelected {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .text {
    display: flex;
  }
  
}
