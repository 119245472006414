.chip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.375rem 0.375rem 0.375rem 0.375rem;
  border-radius: 4px;
  background-color: rgb(83, 93, 180);
  width: 4.25rem;

  .text {
    display: flex;
  }
  
}
