.testResponseContainer {
  overflow: auto;
}

.testResponseItemList {
  position: relative;
}

.testResponseItem {
  width: 100%;

  .name {
    white-space: nowrap;
  }

  .buttonContent {
    background-color: unset;
    // position: sticky;
    // right: 0;
    // // left: 39.75rem;
    // top: -0.5rem;
  }

  &:hover {
    .buttonContent {
      background-color: unset;
    }
  }
}
