.mainBlockCollapsed {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  // MANUAL OVERRIDE STARTS
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  touch-action: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  user-select: none;
  // MANUAL OVERRIDE ENDS

  .canvas {
    display: flex;
    width: 62rem;
    height: unset;
    flex: 1 1 auto;
  }
  
}

.mainBlockExpanded {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  // MANUAL OVERRIDE STARTS
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  touch-action: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
  user-select: none;
  // MANUAL OVERRIDE ENDS

  .addElementsPanel {
    display: flex;
    width: 15.0625rem;
    height: unset;
    align-self: stretch;
  }
  
  // MANUAL OVERRIDE STARTS
  .layersPanel {
    display: flex;
    width: 15.0625rem;
    height: unset;
    align-self: stretch;
  }

  .pageSettingsPanel {
    display: flex;
    width: 15.0625rem;
    height: unset;
    align-self: stretch;
  }
  // MANUAL OVERRIDE ENDS

  .canvas {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
}
  
// MANUAL OVERRIDE STARTS
.dragHandle {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 4rem;
  height: 3rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #101114;
  border-radius: 0.25rem;
  z-index: 50;
}

.dragHandleDragging {
  display: flex;
}

.dragHandleTransparent {
  opacity: 0.6;
}

.elementDragging {
  cursor: grabbing;
}

.elementDropForbidden {
  cursor: no-drop;
}
// MANUAL OVERRIDE ENDS
