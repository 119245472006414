.textDivider {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(39, 43, 51);

  .text {
    display: flex;
  }
  
}
