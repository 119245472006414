.elementItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .elementLogoContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: rgb(16, 17, 20);
    width: unset;
    height: 3rem;
    align-self: stretch;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .text {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
}
