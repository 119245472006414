.breakpointMenu {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .buttonContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .desktopButton {
    display: flex;
    width: 3rem;
    height: 3rem;
  }
  
  .divider {
    display: block;
    width: 0.0625rem;
    height: unset;
    align-self: stretch;
  }
  
  .tabletButton {
    display: flex;
    width: 3rem;
    height: 3rem;
  }
  
  .divider1 {
    display: block;
    width: 0.0625rem;
    height: unset;
    align-self: stretch;
  }
  
  .mobileButton {
    display: flex;
    width: 3rem;
    height: 3rem;
  }
  
  .divider2 {
    display: block;
    width: 0.0625rem;
    height: unset;
    align-self: stretch;
  }
  
  .canvasResolutionSpec {
    display: flex;
    align-self: center;
    width: 6.8125rem;
    height: 3rem;
  }
  
}
