.uIBuilderPage {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .mainContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    overflow: hidden; // MANUAL OVERRIDE
    position: relative; // MANUAL OVERRIDE
  }
  
  .mainBlock {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .logicBuilderPanel {
    display: flex;
    width: 100%; // MANUAL OVERRIDE
    align-self: stretch;
  }
  
  .styleSettingsBlock {
    display: flex;
    width: 20rem;
    height: unset;
    align-self: stretch;
  }
  
}
