$thickness: 0.25rem;

.dropZoneVertical,
.dropZoneHorizontal {
  position: absolute;
  box-sizing: border-box;
  z-index: 30;
  opacity: 0;
}

.dropZoneVertical {
  min-width: $thickness;
  height: -webkit-fill-available;
  height: -moz-available;
}

.dropZoneHorizontal {
  width: -webkit-fill-available;
  width: -moz-available;
  min-height: $thickness;
}

.innerDropZone {
  position: absolute;
  background-color: rgba(83, 93, 180, 1);
  border-radius: $thickness;
  width: 100%;
  height: 100%;
}

.hovered {
  opacity: 1;
}
