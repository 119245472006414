.settingsPanel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 1 auto;
  width: 20rem;
  overflow: auto; // MANUAL OVERRIDE
  
  .settingsRowList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
