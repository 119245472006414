.logicTabList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  background-color: rgba(26, 28, 34, 0.5);
  gap: 0.5rem;

  .logicTab {
    display: flex;
    height: 5rem;
  }
  
}
