.backgroundPanelCollapsed {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 20rem;

  .panelHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.backgroundPanelExpanded {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 20rem;

  .panelHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .backgroundContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    align-self: stretch;
  }
  
  .replaceRow {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .colourRow {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
}
