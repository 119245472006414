.infoTestField {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .labelContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 0rem 0.5rem 0rem;
    height: unset;
    align-self: stretch;
  }
  
  .label {
    display: flex;
  }
  
  .inputContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .inputField {
    display: flex;
    width: unset;
    height: 2rem;
    align-self: stretch;
  }
  
  .text {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
}
