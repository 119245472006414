.toggleItemLargeTextSelectedDefault {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(65, 71, 85);
  cursor: pointer; // MANUAL OVERRIDE

  .text {
    display: flex;
  }
  
}

.toggleItemLargeTextUnselectedDefault {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(7, 7, 9);
  cursor: pointer; // MANUAL OVERRIDE

  .text {
    display: flex;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(113, 124, 198);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(39, 43, 51);
  
  }
}

.toggleItemSmallIconSelectedContextMenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(65, 71, 85);
  cursor: pointer; // MANUAL OVERRIDE

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
}

.toggleItemSmallIconSelectedDefault {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(65, 71, 85);
  cursor: pointer; // MANUAL OVERRIDE

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
}

.toggleItemSmallIconUnselectedContextMenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  cursor: pointer; // MANUAL OVERRIDE

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(113, 124, 198);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(39, 43, 51);
  
  }
}

.toggleItemSmallIconUnselectedDefault {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(7, 7, 9);
  cursor: pointer; // MANUAL OVERRIDE

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(113, 124, 198);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(39, 43, 51);
  
  }
}

.toggleItemSmallIconTextSelectedDefault {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(65, 71, 85);
  cursor: pointer; // MANUAL OVERRIDE

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.25rem;
  }
  
}

.toggleItemSmallIconTextUnselectedDefault {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(7, 7, 9);
  cursor: pointer; // MANUAL OVERRIDE

  .text {
    display: flex;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(113, 124, 198);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(39, 43, 51);
  
  }
}

.toggleItemSmallTextSelectedDefault {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(65, 71, 85);
  cursor: pointer; // MANUAL OVERRIDE

  .text {
    display: flex;
  }
  
}

.toggleItemSmallTextUnselectedDefault {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(7, 7, 9);
  cursor: pointer; // MANUAL OVERRIDE

  .text {
    display: flex;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(113, 124, 198);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(39, 43, 51);
  
  }
}
