.testResponseItemList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  .testResponseItem {
    display: flex;
    width: unset;
    height: 2.5rem;
    align-self: stretch;
  }
  
}
