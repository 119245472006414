.selectedContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  background-color: rgb(26, 28, 34);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  .rowLabel {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .chip {
    display: flex;
    width: auto; // MANUAL OVERRIDE
    margin-left: 0.5rem;
  }
  
}
