.clipDropdownItemList {
  min-width: unset !important;
  overflow: hidden;
}

.dropdownItemListCheckmark {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.25rem 0.25rem 0.25rem 0.25rem;
  border-radius: 4px;
  background-color: rgb(39, 43, 51);
  outline: 1px solid rgb(46, 51, 62);
  outline-offset: 0;
  box-shadow: 3px 4px 12px rgba(0, 0, 0, 0.5);
  position: relative; // MANUAL OVERRIDE
  z-index: 40; // MANUAL OVERRIDE
  min-width: max-content; // MANUAL OVERRIDE

  .dropdownItem {
    z-index: 40; // MANUAL OVERRIDE
    display: flex;
    width: unset;
    height: 2rem;
    align-self: stretch;
  }
  
}

.dropdownItemListDefault {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.25rem 0.25rem 0.25rem 0.25rem;
  border-radius: 4px;
  background-color: rgb(39, 43, 51);
  outline: 1px solid rgb(46, 51, 62);
  outline-offset: 0;
  box-shadow: 3px 4px 12px rgba(0, 0, 0, 0.5);
  position: relative; // MANUAL OVERRIDE
  z-index: 40; // MANUAL OVERRIDE
  min-width: max-content; // MANUAL OVERRIDE

  .dropdownItem {
    z-index: 40; // MANUAL OVERRIDE
    display: flex;
    // width: unset; // MANUAL OVERRIDE
    // height: unset; // MANUAL OVERRIDE
    align-self: stretch;
  }
  
}

.dropdownItemListMenu {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.25rem 0.25rem 0.25rem 0.25rem;
  border-radius: 4px;
  background-color: rgb(39, 43, 51);
  outline: 1px solid rgb(46, 51, 62);
  outline-offset: 0;
  box-shadow: 3px 4px 12px rgba(0, 0, 0, 0.5);
  position: relative; // MANUAL OVERRIDE
  z-index: 40; // MANUAL OVERRIDE
  min-width: max-content; // MANUAL OVERRIDE

  .dropdownItem {
    z-index: 40; // MANUAL OVERRIDE
    display: flex;
    width: unset;
    height: 2rem;
    align-self: stretch;
  }
  
}