.alignmentGrid {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(7, 7, 9);

  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .button {
    display: flex;
  }
  
  .button1 {
    display: flex;
    margin-left: 0.125rem;
  }
  
  .button2 {
    display: flex;
    margin-left: 0.125rem;
  }
  
  .middleContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0.125rem;
  }
  
  .button3 {
    display: flex;
  }
  
  .button4 {
    display: flex;
    margin-left: 0.125rem;
  }
  
  .button5 {
    display: flex;
    margin-left: 0.125rem;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0.125rem;
  }
  
  .button6 {
    display: flex;
  }
  
  .button7 {
    display: flex;
    margin-left: 0.125rem;
  }
  
  .button8 {
    display: flex;
    margin-left: 0.125rem;
  }
  
}
