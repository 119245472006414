.variablesContentEmpty {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(26, 28, 34);

  .heading {
    display: flex;
    width: 18.5rem;
  }
  
  .emptyStateInfo {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
}

.variablesContentFilled {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(26, 28, 34);

  .heading {
    display: flex;
    width: 18.5rem;
  }
  
  .variableCardList {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
}
