.propertyItemDefaultImage {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(7, 7, 9);
  height: 2rem;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(113, 124, 198);
    outline-offset: 0;
  
  }
}

.propertyItemDefaultText {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(7, 7, 9);
  height: 2rem;

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  &:disabled {
    background-color: rgb(46, 51, 62);
  
    .button {
      display: none;
      width: unset;
      height: unset;
    }
    
  }
  &:focus-visible {
    outline: 2px dashed rgb(113, 124, 198);
    outline-offset: 0;
  
  }
}

.propertyItemVariableImage {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(7, 7, 9);
  height: 2rem;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(113, 124, 198);
    outline-offset: 0;
  
  }
}

.propertyItemVariableText {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(7, 7, 9);
  height: 2rem;

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(113, 124, 198);
    outline-offset: 0;
  
  }
}
