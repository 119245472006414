.responseStructureBlockEmpty {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 3rem 3rem 3rem 3rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  outline: 1px solid rgb(63, 71, 86);
  outline-offset: 0;
  width: 56.9375rem;

  .headerContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .labelContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .text {
    display: flex;
  }
  
  .buttonContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 1.5rem;
  }
  
  .button {
    display: flex;
  }
  
  .emptyStateInfo {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
}

.responseStructureBlockFilled {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 3rem 3rem 3rem 3rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  outline: 1px solid rgb(63, 71, 86);
  outline-offset: 0;
  width: 56.9375rem;
  height: 50.75rem;

  .headerContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .labelContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .text {
    display: flex;
  }
  
  .buttonContent {
    display: flex;
    flex-direction: row;
    justify-content: center; // Manual Override
    align-items: center; // Manual Override
    margin-left: 1.5rem;
  }
  
  .statusChip {
    display: flex;
    width: 6rem;
    height: 2rem;
  }
  
  .button {
    display: flex;
    margin-left: 0.5rem;
  }
  
  .testResponseContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    border-radius: 4px;
    background-color: rgb(17, 18, 22);
    width: unset;
    height: 41.25rem;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .testResponseItemList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
