.dropdownItemDefaultDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 1.6rem; // MANUAL OVERRIDE
  border-radius: 4px;
  background-color: rgb(39, 43, 51);
  height: 2rem;

  .text {
    display: flex;
  }

  &:hover { // MANUAL OVERRIDE
    p {
      color: #E9ECF5;
    }
    cursor: pointer;
  }
  &:active {
    background-color: rgb(65, 71, 85);
  }
  &:focus-visible {
    background-color: rgb(65, 71, 85);
    outline: 2px dashed rgb(83, 93, 180);
    outline-offset: 0;
  
  }
}

.dropdownItemDefaultIconText {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem; // MANUAL OVERRIDE
  border-radius: 4px;
  background-color: rgb(39, 43, 51); // MANUAL OVERRIDE
  height: 2rem;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.25rem;
  }

  &:hover { // MANUAL OVERRIDE
    p {
      color: #E9ECF5; // MANUAL OVERRIDE
    }
    cursor: pointer;
  }
  &:active {
    background-color: rgb(65, 71, 85);
  
  }
  &:focus-visible {
    background-color: rgb(65, 71, 85);
    outline: 2px dashed rgb(83, 93, 180);
    outline-offset: 0;
  
  }
}

.dropdownItemDefaultIconTextDescription {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.75rem 0.75rem 0.75rem;
  border-radius: 2px;
  background-color: rgb(39, 43, 51);
  width: 14rem;

  .iconContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .textContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .label {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  &:hover { // MANUAL OVERRIDE
    background-color: rgb(65, 71, 85);
    .label p {
      color: #E9ECF5;
    }
    cursor: pointer;
  }
  &:active {
    background-color: rgb(65, 71, 85);
  }
}

.dropdownItemDefaultImageText {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem; // MANUAL OVERRIDE
  border-radius: 4px;
  background-color: rgb(39, 43, 51);
  height: 2rem;

  .image {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.25rem;
  }
  &:hover { // MANUAL OVERRIDE
    background-color: rgb(65, 71, 85);
    cursor: pointer;
  }
  &:active {
    background-color: rgb(65, 71, 85);
  
  }
  &:focus-visible {
    background-color: rgb(65, 71, 85);
    outline: 2px dashed rgb(83, 93, 180);
    outline-offset: 0;
  
  }
}

.dropdownItemSelectedIconText {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem; // MANUAL OVERRIDE
  border-radius: 4px;
  background-color: rgb(65, 71, 85);
  height: 2rem;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .icon1 {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.25rem;
  }

  .text p {
    color: rgb(233, 236, 245);
  }
  
  &:hover { // MANUAL OVERRIDE
    background-color: rgb(65, 71, 85);
    cursor: pointer;
  }
  &:active {
    background-color: rgb(65, 71, 85);
  
  }
  &:focus-visible {
    background-color: rgb(65, 71, 85);
    outline: 2px dashed rgb(83, 93, 180);
    outline-offset: 0;
  
  }
}

.dropdownItemDefaultTextOnly {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem; // MANUAL OVERRIDE
  border-radius: 4px;
  background-color: rgb(39, 43, 51);
  height: 2rem;

  .text {
    display: flex;
  }
  
  // MANUAL OVERRIDE STARTS
  &:hover {
    .text p {
      color: rgb(233, 236, 245);
    }
  }
  // MANUAL OVERRIDE ENDS

  &:focus-visible {
    outline: 2px dashed rgb(83, 93, 180);
    outline-offset: 0;
  
  }
}

.dropdownItemSelectedDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem; // MANUAL OVERRIDE
  border-radius: 4px;
  background-color: rgb(65, 71, 85);
  height: 2rem;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }

  &:hover { // MANUAL OVERRIDE
    background-color: rgb(65, 71, 85);
    cursor: pointer;
  }
  .text {
    display: flex;
    margin-left: 0.25rem;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(83, 93, 180);
    outline-offset: 0;
  
  }
}
