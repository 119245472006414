.textCodespaceMediumLeftMedium500NeutralDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(135, 141, 155);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: left;
  }
  
}

.textExtraSmallCenterMedium500NeutralHoverSelected {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .value {
    display: block;
    color: rgb(233, 236, 245);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 0.625rem;
    text-align: center;
  }
  
}

.textExtraSmallCenterRegular400NeutralDefault {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .value {
    display: block;
    color: rgb(135, 141, 155);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 0.75rem;
    text-align: center;
  }
  
}

.textExtraSmallCenterRegular400NeutralHoverSelected {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .value {
    display: block;
    color: rgb(233, 236, 245);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 0.625rem;
    text-align: center;
  }
  
}

.textExtraSmallLeftRegular400ActionDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(198, 116, 184);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: left;
  }
  
}

.textLabelExtraSmallLeftItalic400NeutralDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(135, 141, 155);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 400;
    font-style: italic; // MANUAL OVERRIDE
    line-height: 0.75rem;
    text-align: left;
  }
  
}

.textLabelExtraSmallLeftItalic400NeutralHoverSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(233, 236, 245);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 400;
    font-style: italic; // MANUAL OVERRIDE
    line-height: 0.75rem;
    text-align: left;
  }
  
}

.textLabelExtraSmallLeftMedium500NeutralDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(135, 141, 155);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 0.75rem;
    text-align: left;
  }
  
}

.textLabelExtraSmallLeftMedium500NeutralHoverSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(233, 236, 245);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 0.75rem;
    text-align: left;
  }
  
}

.textLabelExtraSmallLeftRegular400NeutralDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(135, 141, 155);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 0.75rem;
    text-align: left;
  }
  
}

.textLabelExtraSmallLeftRegular400NeutralHoverSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(233, 236, 245);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 0.75rem;
    text-align: left;
  }
  
}

.textLabelExtraSmallRightRegular400NeutralDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(135, 141, 155);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 0.75rem;
    text-align: right;
  }
  
}

.textLabelExtraSmallRightRegular400NeutralHoverSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(233, 236, 245);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: right;
  }
  
}

.textLabelLargeCenterMedium500NeutralHoverSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(233, 236, 245);
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: center;
  }
  
}

.textLabelLargeCenterSemibold600NeutralHoverSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(233, 236, 245);
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: center;
  }
  
}

.textLabelLargeLeftMedium500NeutralDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(135, 141, 155);
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: left;
  }
  
}

.textLabelLargeLeftMedium500NeutralHoverSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(233, 236, 245);
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: left;
  }
  
}

.textLabelLargeLeftRegular400NeutralDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(135, 141, 155);
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  
}

.textLabelMediumCenterRegular400NeutralDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(135, 141, 155);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
  }
  
}

.textLabelMediumCenterRegular400NeutralHoverSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(233, 236, 245);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
  }
  
}

.textLabelMediumLeftMedium500NeutralDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(135, 141, 155);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: left;
  }
  
}

.textLabelMediumLeftMedium500NeutralHoverSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(233, 236, 245);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: left;
  }
  
}

.textLabelMediumLeftRegular400NeutralDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(135, 141, 155);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: left;
  }
  
}

.textLabelMediumLeftRegular400NeutralHoverSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(233, 236, 245);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: left;
  }
  
}

.textLabelMediumLeftSemibold600NeutralHoverSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(233, 236, 245);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: left;
  }
  
}

.textLabelMediumRightRegular400NeutralHoverSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(233, 236, 245);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: right;
  }
  
}

.textLabelSmallCenterMedium500DestructiveSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(204, 51, 61);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: center;
  }
  
}

.textLabelSmallCenterMedium500NeutralDefault {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .value {
    display: block;
    color: rgb(135, 141, 155);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: center;
  }
  
}

.textLabelSmallCenterMedium500NeutralHoverSelected {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .value {
    display: block;
    color: rgb(233, 236, 245);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: center;
  }
  
}

.textLabelSmallCenterMedium500VariableSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(102, 181, 128);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: center;
  }
  
}

.textLabelSmallCenterRegular400NeutralDefault {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .value {
    display: block;
    color: rgb(135, 141, 155);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: center;
  }
  
}

.textLabelSmallCenterRegular400NeutralHoverSelected {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .value {
    display: block;
    color: rgb(233, 236, 245);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: center;
  }
  
}

.textLabelSmallLeftItalic400NeutralDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(135, 141, 155);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: left;
  }
  
}

.textLabelSmallLeftItalic400NeutralHoverSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(233, 236, 245);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    font-style: italic; // MANUAL OVERRIDE
    line-height: 1rem;
    text-align: left;
  }
  
}

.textLabelSmallLeftItalic500NeutralDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(135, 141, 155);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: left;
  }
  
}

.textLabelSmallLeftItalic500NeutralSubdued {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(95, 101, 115);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: left;
  }
  
}

.textLabelSmallLeftMedium500DestructiveSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(204, 51, 61);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: left;
  }
  
}

.textLabelSmallLeftMedium500NeutralDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(135, 141, 155);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: left;
  }
  
}

.textLabelSmallLeftMedium500NeutralHoverSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(233, 236, 245);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: left;
  }
  
}

.textLabelSmallLeftMedium500VariableSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(102, 181, 128);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: left;
  }
  
}

.textLabelSmallLeftRegular400ActionDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(198, 116, 184);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: left;
  }
  
}

.textLabelSmallLeftRegular400NeutralDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(135, 141, 155);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: left;
  }
  
}

.textLabelSmallLeftRegular400NeutralHoverSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(233, 236, 245);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: left;
  }
  
}

.textLabelSmallLeftRegular400VariableSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(102, 181, 128);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: left;
  }
  
}

.textLabelSmallLeftSemibold600NeutralHoverSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(233, 236, 245);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1rem;
    text-align: left;
  }
  
}

.textLabelSmallRightMedium500DestructiveSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(204, 51, 61);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: right;
  }
  
}

.textLabelSmallRightMedium500NeutralDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(135, 141, 155);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: right;
  }
  
}

.textLabelSmallRightMedium500SuccessSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(83, 172, 112);
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: right;
  }
  
}

.textXXLLeftSemibold600NeutralHoverSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(233, 236, 245);
    font-family: 'Inter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: left;
  }
  
}
