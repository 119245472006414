.actionCardDoubleLineDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  outline: 1px solid rgb(46, 51, 62);
  outline-offset: 0;
  width: 14rem;
  height: 5rem;
  cursor: pointer; // MANUAL OVERRIDE

  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .labelContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.5rem;
  }
  
  .dropdownButton {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  .middleContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.125rem 0rem 0.125rem 1.5rem;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .text1 {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.125rem 0rem 0.125rem 1.5rem;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .text2 {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.actionCardDoubleLineSelected {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  outline: 2px solid rgb(113, 124, 198);
  outline-offset: 0;
  width: 14rem;
  height: 5rem;
  cursor: pointer; // MANUAL OVERRIDE

  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .labelContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.5rem;
  }
  
  .dropdownButton {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  .middleContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.125rem 0rem 0.125rem 1.5rem;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .text1 {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.125rem 0rem 0.125rem 1.5rem;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .text2 {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.actionCardEmptyDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  outline: 1px solid rgb(46, 51, 62);
  outline-offset: 0;
  width: 14rem;
  height: 5rem;
  cursor: pointer; // MANUAL OVERRIDE

  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .labelContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.5rem;
  }
  
  .dropdownButton {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  .middleContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.125rem 0rem 0.125rem 1.5rem;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .text1 {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0rem 0rem 0rem 1.5rem;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .text2 {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.actionCardEmptySelected {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  outline: 1px solid rgb(46, 51, 62);
  outline-offset: 0;
  width: 14rem;
  height: 5rem;
  cursor: pointer; // MANUAL OVERRIDE

  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .labelContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.5rem;
  }
  
  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  .middleContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.125rem 0rem 0.125rem 1.5rem;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .text1 {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0rem 0rem 0rem 1.5rem;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .text2 {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.actionCardNavigateURLDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  outline: 1px solid rgb(46, 51, 62);
  outline-offset: 0;
  width: 14rem;
  height: 5rem;
  cursor: pointer; // MANUAL OVERRIDE

  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .labelContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.5rem;
  }
  
  .dropdownButton {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  .middleContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0rem 0rem 0rem 1.5rem;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .uRLContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
  }
  
  .text1 {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 0rem 1.5rem;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .text2 {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  &:hover {
  
    .middleContent {
      justify-content: flex-start;
    }
    
  }
}

.actionCardNavigateURLSelected {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  outline: 2px solid rgb(113, 124, 198);
  outline-offset: 0;
  width: 14rem;
  height: 5rem;
  cursor: pointer; // MANUAL OVERRIDE

  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .labelContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.5rem;
  }
  
  .dropdownButton {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  .middleContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0rem 0rem 0rem 1.5rem;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .uRLContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
  }
  
  .text1 {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 0rem 1.5rem;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .text2 {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.actionCardSingleLineDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  outline: 1px solid rgb(46, 51, 62);
  outline-offset: 0;
  width: 14rem;
  height: 5rem;
  cursor: pointer; // MANUAL OVERRIDE

  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .labelContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.5rem;
  }
  
  .dropdownButton {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  .middleContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.125rem 0rem 0.125rem 1.5rem;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .text1 {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0rem 0rem 0rem 1.5rem;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .text2 {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.actionCardSingleLineSelected {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  outline: 2px solid rgb(113, 124, 198);
  outline-offset: 0;
  width: 14rem;
  height: 5rem;
  cursor: pointer; // MANUAL OVERRIDE

  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .labelContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.5rem;
  }
  
  .dropdownButton {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  .middleContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.125rem 0rem 0.125rem 1.5rem;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .text1 {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0rem 0rem 0rem 1.5rem;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .text2 {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.actionCardUpdateVarDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  outline: 1px solid rgb(46, 51, 62);
  outline-offset: 0;
  width: 14rem;
  height: 5rem;
  cursor: pointer; // MANUAL OVERRIDE

  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .labelContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.5rem;
  }
  
  .dropdownButton {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  .updateContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .arrowContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
  }
  
  .updateArrow {
    display: block;
    width: 0.46024763584136963rem;
    height: 1.648441195487976rem;
  }
  
  .metadataContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
  }
  
  .middleContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.125rem 0rem 0.125rem 1rem;
    width: unset;
    align-self: stretch;
  }
  
  .text1 {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 0rem 1rem;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .text2 {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  &:hover {
  
    .middleContent {
      justify-content: flex-start;
    }
    
  }
}

.actionCardUpdateVarSelected {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  outline: 2px solid rgb(113, 124, 198);
  outline-offset: 0;
  width: 14rem;
  height: 5rem;
  cursor: pointer; // MANUAL OVERRIDE

  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .labelContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.5rem;
  }
  
  .dropdownButton {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  .updateContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .arrowContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
  }
  
  .updateArrow {
    display: block;
    width: 0.46024763584136963rem;
    height: 1.648441195487976rem;
  }
  
  .metadataContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
  }
  
  .middleContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.125rem 0rem 0.125rem 1rem;
    width: unset;
    align-self: stretch;
  }
  
  .text1 {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 0rem 1rem;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .text2 {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.hiddenHandler {
  opacity: 0;
}
