.tabItemDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem 0.875rem 0rem 0rem;
  cursor: pointer; // MANUAL OVERRIDE
  .text {
    display: flex;
  }
  
}

.tabItemError {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .text {
    display: flex;
  }
  
  .icon {
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    margin-left: 0.125rem;
  }
  
}

.tabItemSelected {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.875rem 0rem 0rem;

  .text {
    display: flex;
  }
  
  .underline {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
}
