.lineNumberList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(39, 43, 51);
  gap: 0.25rem;

  .lineNumber {
    display: flex;
    width: 1rem;
  }
  
}
