.inputFieldCodeTextAreaMediumDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(7, 7, 9);
  height: 8.5rem;

  .textarea {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(83, 93, 180);
    outline-offset: 0;
  
  }
}

.inputFieldHexCodeMediumDefault {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  background-color: rgb(7, 7, 9);

  .hash {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0rem 0rem 0rem 0.5rem;
    background-color: rgb(7, 7, 9);
    width: 1rem;
  }
  
  .input {
    display: flex;
  }
  
  .inputField {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0rem 0.5rem 0rem 0rem;
    background-color: rgb(7, 7, 9);
    width: 61rem;
    height: 2rem;
    margin-left: 0.125rem;
  }
  
  .input {
    display: flex;
  }
  
  &:disabled {
    background-color: rgb(46, 51, 62);
  
  }
  &:focus-visible {
    outline: 2px dashed rgb(113, 124, 198);
    outline-offset: 0;
  
  }
}

.inputFieldIconTextMediumDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(7, 7, 9);
  height: 2rem;

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .input {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  &:disabled {
    background-color: rgb(46, 51, 62);
  
  }
  &:focus-visible {
    outline: 2px dashed rgb(113, 124, 198);
    outline-offset: 0;
  
  }
}

.inputFieldTextAreaMediumDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(7, 7, 9);
  height: 6rem;

  .textarea {
    display: flex;
  }
  
  &:disabled {
    background-color: rgb(46, 51, 62);
  
  }
  &:focus-visible {
    outline: 2px dashed rgb(83, 93, 180);
    outline-offset: 0;
  
  }
}

.inputFieldTextAreaSmallDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(7, 7, 9);
  height: 3.5rem;

  .textarea {
    display: flex;
  }
  
  &:disabled {
    background-color: rgb(46, 51, 62);
  
  }
  &:focus-visible {
    outline: 2px dashed rgb(83, 93, 180);
    outline-offset: 0;
  
  }
}

.inputFieldTextMediumDefault {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0rem 0.25rem 0rem 0.25rem;
  border-radius: 4px;
  background-color: rgb(7, 7, 9);
  height: 2rem;

  .input {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  &:disabled {
    background-color: rgb(46, 51, 62);
  
  }
  &:focus-visible {
    outline: 2px dashed rgb(83, 93, 180);
    outline-offset: 0;
  
  }
}

.inputFieldTextMediumError {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0rem 0.25rem 0rem 0.25rem;
  border-radius: 4px;
  background-color: rgb(7, 7, 9);
  outline: 1px solid rgb(204, 51, 61);
  outline-offset: 0;
  height: 2rem;

  .input {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(204, 51, 61);
  
  }
}

.inputFieldTextMediumHighlighted {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0rem 0.25rem 0rem 0.25rem;
  border-radius: 4px;
  background-color: rgb(7, 7, 9);
  outline: 2px solid rgb(83, 93, 180);
  outline-offset: 0;
  height: 2rem;

  .input {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.inputFieldTextIconMediumDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(7, 7, 9);
  height: 2rem;

  .input {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.5rem;
  }
  
  &:disabled {
    background-color: rgb(46, 51, 62);
  
  }
  &:focus-visible {
    outline: 2px dashed rgb(113, 124, 198);
    outline-offset: 0;
  
  }
}

.inputFieldTextUnitMediumDefault {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.0625rem 0.0625rem 0.0625rem 0.25rem;
  border-radius: 4px;
  background-color: rgb(7, 7, 9);
  height: 2rem;

  .input {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .button {
    display: flex;
    width: 1.5rem;
    height: unset;
    align-self: stretch;
  }
  
  &:disabled {
    background-color: rgb(46, 51, 62);
  
  }
  &:focus-visible {
    outline: 2px dashed rgb(83, 93, 180);
    outline-offset: 0;
  
  }
}

.inputFieldTextUnitSmallSelected {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.0625rem 0.0625rem 0.0625rem 0.125rem;
  border-radius: 4px;
  background-color: rgb(7, 7, 9);
  width: 3.375rem;
  height: 1.375rem;

  .input {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .button {
    display: flex;
    width: 1.5rem;
    height: unset;
    align-self: stretch;
    margin-left: 0.25rem;
  }
  
  &:disabled {
    background-color: rgb(46, 51, 62);
  
  }
  &:focus-visible {
    outline: 2px dashed rgb(83, 93, 180);
    outline-offset: 0;
  
  }
}
