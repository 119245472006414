.paddingMarginGrid {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 18.5rem;
  height: 9rem;

  .topMargin {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(39, 43, 51);
    width: 18.5rem;
    height: 1.875rem;
  }
  
  .marginTitle {
    display: flex;
    width: 6.5625rem;
  }
  
  .topMarginInput {
    display: flex;
    width: 3.375rem;
    height: 1.375rem;
    margin-left: 1rem;
  }
  
  .middleContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 18.5rem;
    height: 5.25rem;
  }
  
  .leftMargin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(39, 43, 51);
    width: 4.25rem;
    height: 5.25rem;
  }
  
  .leftMarginInput {
    display: flex;
    width: 3.375rem;
    height: 1.375rem;
  }
  
  .paddingGrid {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    outline: 2px solid rgb(26, 28, 34);
    outline-offset: 0;
    width: 10rem;
    height: 5.25rem;
    z-index: 1;
  }
  
  .topPadding {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(39, 43, 51);
    width: 10rem;
    height: 1.75rem;
  }
  
  .paddingTitle {
    display: flex;
    width: 2.3125rem;
    padding: 0rem 0rem 0rem 0.5rem;
  }
  
  .topPaddingInput {
    display: flex;
    width: 3.375rem;
    height: 1.375rem;
    margin-left: 1rem;
  }
  
  .middleContent1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(39, 43, 51);
    width: 10rem;
    height: 1.75rem;
  }
  
  .leftPadding {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(39, 43, 51);
    width: 3.8125rem;
    height: 1.75rem;
  }
  
  .leftPaddingInput {
    display: flex;
    width: 3.375rem;
    height: 1.375rem;
  }
  
  .padMarginCenter {
    display: flex;
    width: 2.375rem;
    height: 1.5rem;
  }
  
  .rightPadding {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(39, 43, 51);
    width: 3.8125rem;
    height: 1.75rem;
  }
  
  .rightPaddingInput {
    display: flex;
    width: 3.375rem;
    height: 1.375rem;
  }
  
  .bottomPadding {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(39, 43, 51);
    width: 10rem;
    height: 1.75rem;
  }
  
  .bottomPaddingInput {
    display: flex;
    width: 3.375rem;
    height: 1.375rem;
  }
  
  .rightMargin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(39, 43, 51);
    width: 4.25rem;
    height: 5.25rem;
  }
  
  .rightMarginInput {
    display: flex;
    width: 3.375rem;
    height: 1.375rem;
  }
  
  .bottomMargin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(39, 43, 51);
    width: 18.5rem;
    height: 1.875rem;
  }
  
  .bottomMarginInput {
    display: flex;
    width: 3.375rem;
    height: 1.375rem;
  }
  
}
