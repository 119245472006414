.rowHoverCapClickable {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: linear-gradient(to left, rgba(39, 43, 51, 1) 76%, rgba(39, 43, 51, 0) 100%); // MANUAL OVERRIDE
  width: 2.875rem;
  height: 1.5rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
  }
  
}

.rowHoverCapNonClickable {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: linear-gradient(to left, rgba(39, 43, 51, 1) 76%, rgba(39, 43, 51, 0) 100%); // MANUAL OVERRIDE
  width: 2.875rem;
  height: 1.5rem;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
}
