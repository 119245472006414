.lineNumber {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem 0.5rem 0rem 0.5rem;
  width: 1rem;

  .text {
    display: flex;
  }
  
}
