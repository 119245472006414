.menuHeaderDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  background-color: rgb(26, 28, 34);
  height: 2.5rem;

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .button {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.5rem;
  }
  
}

.menuHeaderVariable {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  background-color: rgb(26, 28, 34);
  height: 2.5rem;

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .button {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.5rem;
  }
  
}
