.actionPanelCallAPIExpanded {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 20rem;

  .panelHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .panelContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    align-self: stretch;
  }
  
  .actionContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .rowLabel {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .dropdownButton {
    display: flex;
    width: 13.5rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .groupContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .rowLabel1 {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .dropdownButton1 {
    display: flex;
    width: 13.5rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .callFunctionContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .rowLabel2 {
    display: flex;
    width: 4.5rem;
    height: unset;
    align-self: stretch;
  }
  
  .dropdownButton2 {
    display: flex;
    width: 13.5rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.actionPanelEmptyCollapsed {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 20rem;

  .panelHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.actionPanelNagivatePageExpanded {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 20rem;

  .panelHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .panelContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    align-self: stretch;
  }
  
  .actionContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .rowLabel {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .dropdownButton {
    display: flex;
    width: 13.5rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .groupContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .rowLabel1 {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .dropdownButton1 {
    display: flex;
    width: 13.5rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .pageContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .rowLabel2 {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .dropdownButton2 {
    display: flex;
    width: 13.5rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .callFunctionContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .rowLabel3 {
    display: flex;
    width: 4.5rem;
    height: unset;
    align-self: stretch;
  }
  
  .dropdownButton3 {
    display: flex;
    width: 13.5rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.actionPanelNavigateDefaultExpanded {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 20rem;

  .panelHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .panelContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    align-self: stretch;
  }
  
  .actionContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .rowLabel {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .dropdownButton {
    display: flex;
    width: 13.5rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .groupContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .rowLabel1 {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .dropdownButton1 {
    display: flex;
    width: 13.5rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.actionPanelNavigateURLExpanded {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 20rem;

  .panelHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .panelContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    align-self: stretch;
  }
  
  .actionContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .rowLabel {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .dropdownButton {
    display: flex;
    width: 13.5rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .groupContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .rowLabel1 {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .dropdownButton1 {
    display: flex;
    width: 13.5rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .replaceRow {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .callFunctionContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .rowLabel2 {
    display: flex;
    width: 4.5rem;
    height: unset;
    align-self: stretch;
  }
  
  .dropdownButton2 {
    display: flex;
    width: 13.5rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.actionPanelUnselectedExpanded {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 20rem;

  .panelHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .panelContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    align-self: stretch;
  }
  
  .actionContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .rowLabel {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .dropdownButton {
    display: flex;
    width: 13.5rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.actionPanelUpdateVarExpanded {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 20rem;

  .panelHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .panelContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    align-self: stretch;
  }
  
  .actionContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .rowLabel {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .dropdownButton {
    display: flex;
    width: 13.5rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .groupContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .rowLabel1 {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .dropdownButton1 {
    display: flex;
    width: 13.5rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .replaceRow {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
}

.actionPanelCollapsed {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 20rem;

  .panelHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.actionPanelExpanded {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 20rem;

  .panelHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .panelContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    align-self: stretch;
  }
  
  .verticalRowList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
