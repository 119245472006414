.variableResponsePanelCollapsed {
  display: flex;
  width: 100%; // MANUAL OVERRIDE
  height: 2.5625rem;

  .panelHeader {
    display: flex;
    width: 100%; // MANUAL OVERRIDE
  }
  
}

.variableResponsePanelExpanded {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%; // MANUAL OVERRIDE

  .panelHeader {
    display: flex;
    width: 100%; // MANUAL OVERRIDE
  }
  
  .panelContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    background-color: rgb(26, 28, 34);
    // width: 100%; // MANUAL OVERRIDE
  }
  
  .variablesContent {
    display: flex;
  }
  
  .responseContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1.375rem;
    width: 100%; // MANUAL OVERRIDE
  }
  
  .heading {
    display: flex;
    width: 18.5rem;
  }
  
  .instructions {
    display: flex;
    width: 18.5rem;
    margin-top: 0.5rem;
  }
  
  .responseContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.25rem 0.25rem 0.25rem 0.25rem;
    border-radius: 4px;
    background-color: rgb(17, 18, 22);
    outline: 1px solid rgb(63, 71, 86);
    outline-offset: 0;
    width: 100%; // MANUAL OVERRIDE
    height: 33.5rem;
    margin-top: 0.5rem;
  }
  
  .responseItemList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

// MANUAL OVERRIDE START
.backdrop {
  &#{&} { // Increase CSS specificity
    background: rgb(0, 0, 0);
    opacity: 0.5;
  }
}
// MANUAL OVERRIDE END
