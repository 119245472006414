.pageVariablesPanelCollapsed {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(26, 28, 34);

  .panelHeader {
    display: flex;
    width: 15.0625rem;
  }
  
}

.pageVariablesPanelExpanded {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(26, 28, 34);

  .panelHeader {
    display: flex;
    width: 15.0625rem;
  }
  
  .panelContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    width: 15.0625rem;
    box-sizing: border-box; // MANUAL OVERRIDE
    overflow: hidden; // MANUAL OVERRIDE
    padding-bottom: 3.75rem; // MANUAL OVERRIDE
  }
  
  .buttonContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .button {
    display: flex;
    width: unset;
    height: 2rem;
    align-self: stretch;
  }
  
  .variableCardList {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
    overflow: auto; // MANUAL OVERRIDE
  }
  
}
