.elementItemList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.5rem;

  .elementItem {
    display: flex;
    width: calc(33.333333333333336% - 0.3333333333333333rem);
  }
  
}
