.pageSettings {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 15.0625rem;

  .backHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .panelContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    align-self: stretch;
  }
  
  .nameContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .text {
    display: flex;
    width: 3.25rem;
  }
  
  .inputField {
    display: flex;
    width: 7.75rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
    box-sizing: border-box; // MANUAL OVERRIDE
  }
  
  .button {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.5rem;
  }
  
  .uRLContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .text1 {
    display: flex;
    width: 3.25rem;
  }
  
  .inputField1 {
    display: flex;
    width: 9.75rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
    box-sizing: border-box; // MANUAL OVERRIDE
  }
  
  .pathContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .labelContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 0rem 0rem 0rem;
  }
  
  .text2 {
    display: flex;
    width: 3.25rem;
    height: 3.5rem;
  }
  
  .inputField2 {
    display: flex;
    width: 9.75rem; // MANUAL OVERRIDE
    height: 6rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
    box-sizing: border-box; // MANUAL OVERRIDE
  }
  
}
