.logicBuilderHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  background-color: rgb(26, 28, 34);
  height: 3rem;

  .text {
    display: flex;
    // width: 62rem; // MANUAL OVERRIDE
  }
  
  .grabIconButton {
    display: flex;
    margin-left: 2.5rem;
  }
  
}
