.topNav {
  z-index: 3;

  .button {
    align-self: stretch;
    flex: initial;
  }

  .leftContent {
    flex: initial;
    align-self: stretch;
  }

  .rightContent {
    flex: initial;
    align-self: stretch;
  }

  .publish {
    align-self: stretch;
    flex: initial;
  }
  
  .spindlLogo {
    width: unset;
  }
}