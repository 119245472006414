.groupsPanel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(26, 28, 34);
  width: 15.0625rem;

  .sectionLabel {
    display: flex;
    width: unset;
    height: 3rem;
    align-self: stretch;
  }
  
  .groupContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0.75rem 0.75rem 0.75rem;
    width: unset;
    align-self: stretch;
  }
  
  .button {
    display: flex;
    width: unset;
    height: 2rem;
    align-self: stretch;
  }
  
  .textCardList {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
    // MANUAL OVERRIDE STARTS
    // MAX_HEIGHT = 100vh - topNav - sectionLabel - groupsPanel Button
    max-height: calc(100vh - 3.0625rem - 3rem - 3.75rem);
    overflow-y: auto;
    flex-wrap: nowrap;
    // MANUAL OVERRIDE ENDS
  }
  
}
