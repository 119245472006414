.endpointCard {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 4px;
  background-color: rgb(39, 43, 51);
  height: 4rem;

  .methodChip {
    display: flex;
    width: 4.75rem;
    height: 2rem;
  }
  
  .endpointContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
    margin-left: 1.9375rem;
  }
  
  .label {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .path {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  &:active {
    background-color: rgb(65, 71, 85);
  
  }
  &:hover {
    background-color: rgb(65, 71, 85);
  
  }
}
