.flowStartCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  border-radius: 100px;
  background-color: rgb(26, 28, 34);
  outline: 1px solid rgb(63, 71, 86);
  outline-offset: 0;
  width: 8.6875rem;

  .text {
    display: flex;
  }
  
}

.hiddenHandler {
  opacity: 0;
}