.cornersPanelCollapsed {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 20rem;

  .panelHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.cornersPanelExpandedSeperate {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 20rem;

  .panelHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .cornersContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    align-self: stretch;
  }
  
  .radiusContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .radius {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .inputField {
    display: flex;
    width: 6.5rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .toggleItemList {
    display: flex;
    width: 6.5rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .seperateCornerContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 0rem 5rem;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .topLeft {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
  }
  
  .topRight {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .bottomLeft {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
  }
  
  .bottomRight {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.cornersPanelExpandedUnion {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 20rem;

  .panelHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .cornersContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    align-self: stretch;
  }
  
  .radiusContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .radius {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .inputField {
    display: flex;
    width: 6.5rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .toggleItemList {
    display: flex;
    width: 6.5rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}
