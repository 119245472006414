.groupPageDefaultEndpoints {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(7, 7, 9);
  width: 64rem;
  height: 100%;

  .pageContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    overflow: auto; // MANUAL OVERRIDE
  }

  .leftContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 18.125rem;
    height: unset;
    align-self: stretch;
  }

  .groupsPanel {
    display: flex;
    width: 15.0625rem;
    height: unset;
    align-self: stretch;
  }

  .rightContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem 2rem 2rem 2rem;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    overflow: auto; // MANUAL OVERRIDE
  }

  .titleContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .groupName {
    display: flex;
    width: 41.875rem;
  }

  .baseURL {
    display: flex;
    width: 41.875rem;
    margin-top: 0.25rem;
  }

  .groupContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1rem;
  }

  .navContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 41.875rem;
  }

  .tabItemList {
    display: flex;
  }

  .button {
    display: flex;
    margin-left: 1rem;
  }

  .headerBlock {
    display: flex;
    margin-top: 0.5rem;
    align-self: stretch;
  }

}

.groupPageEmptyGroups {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(7, 7, 9);
  width: 64rem;
  height: 41.6875rem;

  .pageContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }

  .leftContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 0 1 auto; // MANUAL OVERRIDE
    align-self: stretch; // MANUAL OVERRIDE
  }

  .groupsPanel {
    display: flex;
    width: 15.0625rem;
    height: unset;
    align-self: stretch;
  }

  .rightContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 11.25rem 2rem 2rem 2rem;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 44.9375rem;
  }

  .groupContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }

  .emptyGroupBlock {
    display: flex;
    width: unset;
    align-self: stretch;
  }

}

// MANUAL OVERRIDE STARTS
.textModal {
  &#{&} {
    // Increase CSS specificity
    border: none;
  }
}

.backdrop {
  &#{&} {
    // Increase CSS specificity
    background: rgb(7, 7, 9);
    opacity: 0.6;
  }
}

.headerInputField {
  input {
    width: 100%;
  }
}

.tableRowList {
  overflow-y: auto;
  max-height: calc(100vh - 21.5rem);
  flex-wrap: nowrap;
}

// MANUAL OVERRIDE ENDS