.textCardDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(7, 7, 9);
  height: 3.5rem;

  .headerContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .label {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .button {
    display: none;
  }
  
  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  &:active {
    background-color: rgb(65, 71, 85);
  
    .button {
      display: flex;
      width: 1rem;
      height: 1rem;
      margin-left: 1.5rem;
    }
    
  }
  &:hover {
    background-color: rgb(39, 43, 51);
    width: 14.9375rem;
  
    .button {
      display: flex;
      width: 1rem;
      height: 1rem;
      margin-left: 1.5rem;
    }
    
  }
}

.textCardSelected {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(65, 71, 85);
  height: 3.5rem;

  .headerContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .label {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 1.5rem;
  }
  
  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
