.styleEffectsPanelCollapsed {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 20rem;

  .panelHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.styleEffectsPanelExpanded {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 20rem;

  .panelHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .panelContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    align-self: stretch;
  }
  
  .visibleContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .rowLabel {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .toggleItemList {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .opacityContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .rowLabel1 {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .inputField {
    display: flex;
    width: 4.5rem;
    height: 2rem;
    margin-left: 0.5rem;
  }
  
  .shadowContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .rowLabel2 {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .button {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .cursorContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .rowLabel3 {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .dropdownButton {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.styleEffectsPanelExpandedShadows {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 20rem;

  .panelHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .panelContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    align-self: stretch;
  }
  
  .visibleContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .rowLabel {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .toggleItemList {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .opacityContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .rowLabel1 {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .inputField {
    display: flex;
    width: 4.5rem;
    height: 2rem;
    margin-left: 0.5rem;
  }
  
  .shadowContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .rowLabel2 {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .button {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 0rem 5rem;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .propertyItemList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .cursorContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .rowLabel3 {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .dropdownButton {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}
