.logicTabDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  border: 2px solid rgb(39, 43, 51);
  outline-offset: 0;
  height: 5rem;
  cursor: pointer; // MANUAL OVERRIDE

  .textContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: 4px;
    background-color: rgb(39, 43, 51);
    width: 8.4375rem;
    height: 5rem;
  }
  
  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  // MANUAL OVERRIDE STARTS
  &:hover {
    .text > p {
      font-weight: 500;
    }
  }
  //MANUAL OVERRIDE ENDS
}

.logicTabSelected {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  border: 2px solid rgb(65, 71, 85);
  outline-offset: 0;
  height: 5rem;
  cursor: pointer; // MANUAL OVERRIDE

  .textContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: 4px;
    background-color: rgb(65, 71, 85);
    outline: 2px solid rgb(7, 7, 9);
    outline-offset: -2px; // MANUAL OVERRIDE
    width: 8.4375rem;
    height: 5rem;
  }
  
  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
}
