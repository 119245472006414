.addImageMenuDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  outline: 1px solid rgb(46, 51, 62);
  outline-offset: 0;
  box-shadow: 3px 4px 12px rgba(0, 0, 0, 0.5);
  width: 20rem;
  height: 100%;

  .menuHeader {
    display: flex;
    width: unset;
    height: 2.25rem;
    align-self: stretch;
  }
  
  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  .menuContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    align-self: stretch;
  }
  
  .imageContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .imageLabel {
    display: flex;
    width: 16.5rem;
  }
  
  .replaceButton {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .sizeContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0.75rem;
  }
  
  .sizeLabel {
    display: flex;
    width: 16.5rem;
  }
  
  .toggleItemList {
    display: flex;
    width: 18.5rem;
    margin-top: 0.5rem;
  }
  
  .positionContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0.75rem;
  }
  
  .positionLabel {
    display: flex;
    width: 16.5rem;
  }
  
  .positionContent1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: 13.5rem;
    margin-top: 0.5rem;
  }
  
  .horizontalPosition {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .dropdownButton {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
  }
  
  .inputField {
    display: flex;
    width: 4.6875rem;
    height: 2rem;
    margin-left: 0.5rem;
  }
  
  .verticalPosition {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .dropdownButton1 {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
  }
  
  .inputField1 {
    display: flex;
    width: 4.6875rem;
    height: 2rem;
    margin-left: 0.5rem;
  }
  
}
