.buttonIconClearMDefaultPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
}

.buttonIconClearMSelectedPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
}

.buttonIconClearSDefaultPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
}

.buttonIconClearSSelectedPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
}

.buttonIconDarkSDefaultPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(7, 7, 9);
  width: 1rem;
  height: 1rem;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  &:active {
    background-color: rgb(65, 71, 85);
  
  }
  &:hover {
    background-color: rgb(26, 28, 34);
  
  }
}

.buttonIconDarkSSelectedPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(65, 71, 85);
  width: 1rem;
  height: 1rem;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
}

.buttonIconRoundedLDefaultPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  width: 1.5rem;
  height: 1.5rem;

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  &:active {
    background-color: rgb(65, 71, 85);
  
  }
  &:hover {
    background-color: rgb(39, 43, 51);
  
  }
}

.buttonIconRoundedLSelectedPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: rgb(65, 71, 85);
  width: 1.5rem;
  height: 1.5rem;

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
}

.buttonIconRoundedMDefaultPrimary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: rgb(83, 93, 180);
  width: 1.5rem;
  height: 1.5rem;

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  &:active {
    background-color: rgb(113, 124, 198);
  
  }
  &:focus-visible {
    background-color: rgb(113, 124, 198);
    outline: 1px dashed rgb(233, 236, 245);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(113, 124, 198);
  
  }
}

.buttonIconRoundedMDefaultPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 3.3333334922790527px;
  background-color: rgb(26, 28, 34);
  width: 1.25rem;
  height: 1.25rem;

  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  &:active {
    background-color: rgb(65, 71, 85);
  
  }
  &:hover {
    background-color: rgb(39, 43, 51);
  
  }
}

.buttonIconRoundedMSelectedPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 3.3333334922790527px;
  background-color: rgb(65, 71, 85);
  width: 1.25rem;
  height: 1.25rem;

  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
  }
  
}

.buttonIconRoundedSDefaultPrimary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background-color: rgb(83, 93, 180);
  width: 1rem;
  height: 1rem;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  &:active {
    background-color: rgb(113, 124, 198);
  
  }
  &:focus-visible {
    background-color: rgb(113, 124, 198);
    outline: 1px dashed rgb(233, 236, 245);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(113, 124, 198);
  
  }
}

.buttonIconLDefaultPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(26, 28, 34);
  width: 3rem;
  height: 3rem;

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  &:active {
    background-color: rgb(65, 71, 85);
  
  }
}

.buttonIconLSelectedPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(65, 71, 85);
  width: 3rem;
  height: 3rem;

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
}

.buttonIconMDefaultPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(26, 28, 34);
  width: 1.5rem;
  height: 1.5rem;

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  &:active {
    background-color: rgb(65, 71, 85);
  
  }
}

.buttonIconMSelectedPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(65, 71, 85);
  width: 1.5rem;
  height: 1.5rem;

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
}

.buttonIconSDefaultPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(26, 28, 34);
  width: 1rem;
  height: 1rem;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  &:active {
    background-color: rgb(65, 71, 85);
  
  }
}

.buttonIconSSelectedPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(65, 71, 85);
  width: 1rem;
  height: 1rem;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
}

.buttonIconTextClearMDefaultPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.25rem;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(113, 124, 198);
    outline-offset: 0;
  
  }
}

.buttonIconTextClearMSelectedPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.25rem;
  }
  
}

.buttonIconTextMDefaultPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(39, 43, 51);

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.25rem;
  }
  
  &:active {
    background-color: rgb(65, 71, 85);
  
  }
  &:disabled {
    background-color: rgb(46, 51, 62);
  
  }
  &:focus-visible {
    background-color: rgb(65, 71, 85);
    outline: 2px dashed rgb(113, 124, 198);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(65, 71, 85);
  
  }
}

.buttonIconTextMSelectedPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(65, 71, 85);

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.25rem;
  }
  
}

.buttonImageMDefaultPrimarySubdued {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .image {
    display: block;
    width: 2rem;
    height: 2rem;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(83, 93, 180);
    outline-offset: 0;
  
  }
}

.buttonPageNameMDefaultPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0rem 0.75rem 0rem 0.75rem;
  background-color: rgb(26, 28, 34);

  .label {
    display: flex;
  }
  
  .pageName {
    display: flex;
    margin-left: 0.25rem;
  }
  
  &:active {
    background-color: rgb(65, 71, 85);
  
  }
}

.buttonTextIconMDefaultPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(39, 43, 51);

  .text {
    display: flex;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
  }
  
  &:active {
    background-color: rgb(65, 71, 85);
  
  }
  &:disabled {
    background-color: rgb(46, 51, 62);
  
  }
  &:focus-visible {
    background-color: rgb(65, 71, 85);
    outline: 2px dashed rgb(113, 124, 198);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(65, 71, 85);
  
  }
}

.buttonTextIconMSelectedPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(65, 71, 85);

  .text {
    display: flex;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
  }
  
}

.buttonTextOnlyMDefaultPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(39, 43, 51);

  .text {
    display: flex;
  }
  
  &:active {
    background-color: rgb(65, 71, 85);
  
  }
  &:disabled {
    background-color: rgb(46, 51, 62);
  
  }
  &:focus-visible {
    background-color: rgb(65, 71, 85);
    outline: 2px dashed rgb(113, 124, 198);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(65, 71, 85);
  
  }
}

.buttonTextOnlyMSelectedPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(65, 71, 85);

  .text {
    display: flex;
  }
  
}

.buttonTextMDefaultDestructive {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  outline: 1px solid rgb(204, 51, 61);
  outline-offset: 0;

  .text {
    display: flex;
  }
  
  &:active {
    background-color: rgba(204, 51, 61, 0.20000000298023224);
  
  }
  &:disabled {
    background-color: rgb(46, 51, 62);
    outline: 1px solid rgb(65, 71, 85);
  
  }
  &:focus-visible {
    background-color: rgba(204, 51, 61, 0.20000000298023224);
    outline: 2px dashed rgb(204, 51, 61);
  
  }
  &:hover {
    background-color: rgba(204, 51, 61, 0.20000000298023224);
  
  }
}

.buttonTextMDefaultDestructivePrimary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(204, 51, 61);

  .text {
    display: flex;
  }
  
  &:active {
    background-color: rgb(209, 72, 82);
  
  }
  &:disabled {
    background-color: rgb(46, 51, 62);
  
  }
  &:focus-visible {
    background-color: rgb(209, 72, 82);
    outline: 2px dashed rgb(233, 236, 245);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(209, 72, 82);
  
  }
}

.buttonTextMDefaultPrimary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(83, 93, 180);

  .text {
    display: flex;
  }
  
  &:active {
    background-color: rgb(113, 124, 198);
  
  }
  &:disabled {
    background-color: rgb(46, 51, 62);
  
  }
  &:focus-visible {
    background-color: rgb(113, 124, 198);
    outline: 2px dashed rgb(233, 236, 245);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(113, 124, 198);
  
  }
}

.buttonTextMDefaultPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0rem 0.75rem 0rem 0.75rem;
  background-color: rgb(26, 28, 34);

  .text {
    display: flex;
  }
  
  &:active {
    background-color: rgb(65, 71, 85);
  
  }
}

.buttonTextMDefaultSecondary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  outline: 1px solid rgb(65, 71, 85);
  outline-offset: 0;

  .text {
    display: flex;
  }
  
  &:active {
    background-color: rgba(65, 71, 85, 0.20000000298023224);
  
  }
  &:disabled {
    background-color: rgb(46, 51, 62);
  
  }
  &:focus-visible {
    background-color: rgba(65, 71, 85, 0.20000000298023224);
    outline: 2px dashed rgb(65, 71, 85);
  
  }
  &:hover {
    background-color: rgba(65, 71, 85, 0.20000000298023224);
  
  }
}

.buttonTextMSelectedDestructive {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgba(204, 51, 61, 0.20000000298023224);
  outline: 1px solid rgb(204, 51, 61);
  outline-offset: 0;

  .text {
    display: flex;
  }
  
}

.buttonTextMSelectedDestructivePrimary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(209, 72, 82);

  .text {
    display: flex;
  }
  
}

.buttonTextMSelectedPrimary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(113, 124, 198);

  .text {
    display: flex;
  }
  
}

.buttonTextMSelectedSecondary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgba(65, 71, 85, 0.20000000298023224);
  outline: 1px solid rgb(65, 71, 85);
  outline-offset: 0;

  .text {
    display: flex;
  }
  
}

.buttonUnitButtonMDefaultPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0rem 0.125rem 0rem 0.125rem;
  border-radius: 4px;
  background-color: rgb(7, 7, 9);
  width: 1.25rem;
  height: 2rem;

  .text {
    display: flex;
  }
  
  &:active {
    background-color: rgb(65, 71, 85);
  
  }
  &:disabled {
    background-color: rgb(46, 51, 62);
  
  }
  &:hover {
    background-color: rgb(65, 71, 85);
  
  }
}

.buttonUnitButtonMSelectedPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0rem 0.125rem 0rem 0.125rem;
  border-radius: 4px;
  background-color: rgb(65, 71, 85);
  width: 1.25rem;
  height: 2rem;

  .text {
    display: flex;
  }
  
}

.buttonUnitButtonSDefaultPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0rem 0.125rem 0rem 0.125rem;
  border-radius: 4px;
  background-color: rgb(7, 7, 9);
  width: 1.5rem;
  height: 1.25rem;

  .text {
    display: flex;
  }
  
  &:active {
    background-color: rgb(65, 71, 85);
  
  }
  &:disabled {
    background-color: rgb(46, 51, 62);
  
  }
  &:hover {
    background-color: rgb(65, 71, 85);
  
  }
}

.buttonUnitButtonSSelectedPrimarySubdued {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0rem 0.125rem 0rem 0.125rem;
  border-radius: 4px;
  background-color: rgb(65, 71, 85);
  width: 1.5rem;
  height: 1.25rem;

  .text {
    display: flex;
  }
  
}
