.iconActionNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 1.5rem;
  }
  
  .content1 {
    display: block;
    border-radius: 3.000000238418579px;
    background-color: rgb(198, 116, 184);
  }
  
  path[id=content2], [id=content2] path {
    display: block;
    fill: rgb(6, 7, 8);
  }
  
}

.iconAddNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content], path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconAddNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconAddHomeNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconAddHomeNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconAddVariableNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconAddVariableNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconApiEndpointNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconApiEndpointNeutralDefault {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconApiGroupNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconApiGroupNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconArrowDownNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconArrowDownNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconArrowLeftNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconArrowLeftNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconArrowOutwardsRightNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconArrowRightNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconArrowRightNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconArrowUpNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconArrowUpNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconAutoCursorCursor {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=content4], [id=content4] path {
    display: block;
    fill: rgb(255, 255, 255);
  }
  
  path[id=content3], [id=content3] path {
    display: block;
    fill: rgb(255, 255, 255);
  }
  
  path[id=content2], [id=content2] path {
    display: block;
    fill: rgb(0, 0, 0);
  }
  
  path[id=content1], [id=content1] path {
    display: block;
    fill: rgb(0, 0, 0);
  }
  
}

.iconBodyNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconBodyNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconBoldNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconBoldNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconBorderNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconBorderNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconBottomLeftCornerNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.iconBottomRightCornerNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.iconButtonNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconButtonNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconCenterAlignNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconCenterAlignNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconCheckNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconCheckNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconCheckmarkCircleNeutralDefault {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path[id=taskalt], [id=taskalt] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconChevronDownNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconChevronDownNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconChevronRightNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconChevronRightNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconChevronUpNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconChevronUpNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconClearFormatNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconClearFormatNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconCloseNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconCloseNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconClosedHandCursor {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=content2], [id=content2] path {
    display: block;
    fill: rgb(255, 255, 255);
  }
  
  path[id=content1], [id=content1] path {
    display: block;
    // outline: 0.75px solid rgb(0, 0, 0); // MANUAL OVERRIDE
    outline-offset: 0;
  }
  
}

.iconCollectionItemVariableSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Storage], [id=Storage] path {
    display: block;
    fill: rgb(83, 172, 112);
  }
  
}

.iconCollectionListNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path[id=viewstream], [id=viewstream] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconCollectionListNeutralDefault {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path[id=viewstream], [id=viewstream] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconContainerNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconContainerNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconCopyNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconCopyNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconDashedBorderNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconDashedBorderNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconEditNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconEditNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconErrorNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .content1 {
    display: block;
    background-color: rgb(217, 217, 217);
  }
  
  path[id=content2], [id=content2] path {
    display: block;
    border-radius: 1.600001335144043px;
    fill: rgb(204, 51, 61);
  }
  
}

.iconErrorDotNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  .ellipse1 {
    display: block;
    background-color: rgb(204, 51, 61);
    width: 100%;
    height: 100%;
  }
  
}

.iconExpandNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconExpandNeutralDefault {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconFlowVariableVariableSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=conversionpath], [id=conversionpath] path {
    display: block;
    fill: rgb(83, 172, 112);
  }
  
}

.iconGlobalVariableSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(102, 181, 128);
  }
  
}

.iconHiddenNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconHiddenNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconHomeNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconHomeNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconImageNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconImageNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconItalicNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconItalicNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconJustifyNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconJustifyNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconLaptopNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=laptopmac], [id=laptopmac] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconLaptopNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=laptopmac], [id=laptopmac] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconLaptopDefaultNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Vector], [id=Vector] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconLaptopDefaultNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Vector], [id=Vector] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconLayersNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconLayersNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconLeftAlignNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconLeftAlignNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconLetterSpaceNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.iconLineHeightNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.iconMobileNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconMobileNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconMoreVertNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconMoreVertNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconNullNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconNullNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconOpenHandNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=content2], [id=content2] path {
    display: block;
    fill: rgb(255, 255, 255);
  }
  
  path[id=content1], [id=content1] path {
    display: block;
    // outline: 0.75px solid rgb(0, 0, 0);  // MANUAL OVERRIDE
    outline-offset: 0;
  }
  
}

.iconOverlineNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconOverlineNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconPageVariableSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(102, 181, 128);
  }
  
}

.iconPointerCursor {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=content2], [id=content2] path {
    display: block;
    fill: rgb(255, 255, 255);
  }
  
  path[id=content1], [id=content1] path {
    display: block;
    // outline: 0.75px solid rgb(0, 0, 0); // MANUAL OVERRIDE
    outline-offset: 0;
  }
  
}

.iconRedoNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconRedoNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconRightAlignNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconRightAlignNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconRomanNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconRomanNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconSeperateCornersNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconSeperateCornersNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconStrikethroughNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconStrikethroughNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconTabletNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconTabletNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconTextNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconTextNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconTopLeftCornerNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.iconTopRightCornerNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.iconUnderlineNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconUnderlineNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconUndoNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
  path[id=Content1], [id=Content1] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconUndoNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconUnfoldMoreNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconUnfoldMoreNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconUnionCornersNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconUnionCornersNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconVariableNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content2], [id=Content2] path {
    display: block;
    fill: transparent;
  }
  
  path[id=Content1], [id=Content1] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconVariableNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content2], [id=Content2] path {
    display: block;
    fill: transparent;
  }
  
  path[id=Content1], [id=Content1] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}

.iconVariableVariableSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content2], path[id=Content2], path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(41, 72, 51);
  }
  
  path[id=Content1], path[id=Content1], path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(102, 181, 128);
  }
  
}

.iconVisibilityNeturalHoverSelected {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
}

.iconVisibilityNeutralDefault {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(135, 141, 155);
  }
  
}
