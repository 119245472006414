.replaceInputDefault {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.0625rem 0.0625rem 0.0625rem 0.0625rem;
  border-radius: 4px;
  background-color: rgb(39, 43, 51);
  width: 3.375rem;
  height: 1.375rem;

  .text {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
}

.replaceInputSelected {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .inputField {
    display: flex;
    width: 3.375rem;
    height: 1.375rem;
  }
  
}
