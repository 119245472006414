.variableLayerItemList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  .variableLayerItem {
    display: flex;
    // width: 28.875rem; // MANUAL OVERRIDE
    align-self: stretch; // MANUAL OVERRIDE
    height: 2rem;
  }
  
}
