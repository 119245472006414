.topNavDefault {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: rgb(26, 28, 34);
  height: 3.0625rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .leftContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
  }
  
  .logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem 0.5rem 0rem 0.75rem;
    background-color: rgb(26, 28, 34);
    height: unset;
    align-self: stretch;
  }
  
  .spindlLogo {
    display: block;
    width: 4rem;
    height: 1.5rem;
  }
  
  .divider {
    display: block;
    width: 0.0625rem;
    height: unset;
    align-self: stretch;
  }
  
  .appName {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem 0.75rem 0rem 0.75rem;
    background-color: rgb(26, 28, 34);
    height: unset;
    align-self: stretch;
  }
  
  .text {
    display: flex;
  }
  
  .divider1 {
    display: block;
    width: 0.0625rem;
    height: unset;
    align-self: stretch;
  }
  
  .button {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .rightContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
  }
  
  .divider11 {
    display: block;
    width: 0.0625rem;
    height: 3rem;
  }
  
  .breakpointMenu {
    display: flex;
  }
  
  .divider2 {
    display: block;
    width: 0.0625rem;
    height: 3rem;
  }
  
  .saveStatusIndicator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem 0.75rem 0rem 0.75rem;
    background-color: rgb(26, 28, 34);
    height: unset;
    align-self: stretch;
  }
  
  .saveStatus {
    display: flex;
  }
  
  .undo {
    display: flex;
    width: 3rem;
    height: unset;
    align-self: stretch;
  }
  
  .redo {
    display: flex;
    width: 3rem;
    height: unset;
    align-self: stretch;
  }
  
  .divider3 {
    display: block;
    width: 0.0625rem;
    height: 3rem;
  }
  
  .preview {
    display: flex;
    width: 3rem;
    height: unset;
    align-self: stretch;
  }
  
  .divider4 {
    display: block;
    width: 0.0625rem;
    height: 3rem;
  }
  
  .publish {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .bottomDivider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
}

.topNavLogoAppName {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: rgb(26, 28, 34);
  height: 3.0625rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .leftContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
  }
  
  .logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem 0.5rem 0rem 0.75rem;
    background-color: rgb(26, 28, 34);
    height: unset;
    align-self: stretch;
  }
  
  .spindlLogo {
    display: block;
    width: 4rem;
    height: 1.5rem;
  }
  
  .divider {
    display: block;
    width: 0.0625rem;
    height: unset;
    align-self: stretch;
  }
  
  .appName {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem 0.75rem 0rem 0.75rem;
    background-color: rgb(26, 28, 34);
    height: unset;
    align-self: stretch;
  }
  
  .text {
    display: flex;
  }
  
  .divider1 {
    display: block;
    width: 0.0625rem;
    height: unset;
    align-self: stretch;
  }
  
  .bottomDivider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
}
