.canvasResolutionSpec {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.75rem 0rem 0.75rem;
  background-color: rgb(26, 28, 34);

  .breakpointScreenSize {
    display: flex;
  }
  
  .scale {
    display: flex;
    margin-left: 0.5rem;
  }
  
}
