.codeSpace {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  background-color: rgb(39, 43, 51);
  outline: 1px solid rgb(39, 43, 51);
  outline-offset: 0;

  .lineNumberList {
    display: flex;
  }
  
  .inputField {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
}
