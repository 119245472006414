.logicSettingsHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem 0.75rem 0rem 0.75rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  // outline: 1px solid rgb(46, 51, 62); // MANUAL OVERRIDE
  // outline-offset: 0; // MANUAL OVERRIDE
  border: 1px solid rgb(46, 51, 62); // MANUAL OVERRIDE
  box-sizing: border-box; // MANUAL OVERRIDE
  height: 2.5rem; // MANUAL OVERRIDE

  .text {
    display: flex;
    width: unset;
    align-self: stretch;
    justify-content: center; // MANUAL OVERRIDE
  }
  
}
