.pageSettingsPanel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 15.0625rem; // MANUAL OVERRIDE
  align-self: stretch; // MANUAL OVERRIDE

  // MANUAL OVERRIDE STARTS
  &.pageSettingsPanelHidden {
    width: 0;
    overflow: hidden;
  }
  // MANUAL OVERRIDE ENDS

  .pageSettings {
    display: flex;
    width: auto; // MANUAL OVERRIDE
    min-width: 100%; // MANUAL OVERRIDE
  }
  
  .pageVariablesPanel {
    display: flex;
    width: auto; // MANUAL OVERRIDE
    min-width: 100%; // MANUAL OVERRIDE
    overflow: hidden; // MANUAL OVERRIDE
    height: -webkit-fill-available; // MANUAL OVERRIDE
    height: -moz-available; // MANUAL OVERRIDE
  }
  
}
