.duoToneTextDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .lightText {
    display: flex;
  }
  
  .darkText {
    display: flex;
    margin-left: 0.25rem;
  }
  
}
