.leftPanelAddElementsPanel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(26, 28, 34);
  height: 34rem;

  .divider1 {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  .elementItemsSectionLabel {
    display: flex;
    width: unset;
    height: 2.5rem;
    align-self: stretch;
  }
  
  .elementContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0.75rem 1rem 0.75rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    align-self: stretch;
  }
  
  .elementItemList {
    display: flex;
    width: unset;
    height: 5.25rem;
    align-self: stretch;
  }
  
}

.leftPanelLayersPanel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(26, 28, 34);
  height: 34rem;

  .divider1 {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  .elementsSectionLabel {
    display: flex;
    width: unset;
    height: 2.5rem;
    align-self: stretch;
  }
  
  .labelListContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 1rem 0rem;
    width: unset;
    align-self: stretch;
  }
  
  .layerLabelItemList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.leftPanelPagesPanel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(26, 28, 34);
  height: 34rem;

  .divider1 {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  .pagesSectionLabel {
    display: flex;
    width: unset;
    height: 2.5rem;
    align-self: stretch;
  }
  
  .pageListContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 1rem 0rem;
    width: unset;
    align-self: stretch;
  }
  
  .pageLabelItemList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
