.sizePanelCollapsed {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 20rem;

  .panelHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.sizePanelExpanded {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 20rem;

  .panelHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    align-self: stretch;
  }
  
  .widthHeightContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .width {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 9rem; // MANUAL OVERRIDE
    flex: 1 1 auto;
  }
  
  .rowLabel {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .inputField {
    display: flex;
    width: 4rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .height {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 9rem; // MANUAL OVERRIDE
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .rowLabel1 {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .inputField1 {
    display: flex;
    width: 4rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .textDivider {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .minContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .minW {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 9rem; // MANUAL OVERRIDE
    flex: 1 1 auto;
  }
  
  .rowLabel2 {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .inputField2 {
    display: flex;
    width: 4rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .minH {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 9rem; // MANUAL OVERRIDE
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .rowLabel3 {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .inputField3 {
    display: flex;
    width: 4rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .maxContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .maxW {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 9rem; // MANUAL OVERRIDE
    flex: 1 1 auto;
  }
  
  .rowLabel4 {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .inputField4 {
    display: flex;
    width: 4rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .maxH {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 9rem; // MANUAL OVERRIDE
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .rowLabel5 {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .inputField5 {
    display: flex;
    width: 4rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .overflowContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .font {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .dropdownButton {
    display: flex;
    width: 13.5rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
    box-sizing: border-box; // MANUAL OVERRIDE
  }
  
}
