.padMarginCenter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  background-color: rgb(7, 7, 9);
  width: 2.375rem;
  height: 1.5rem;

}
