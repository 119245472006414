.propertyItemList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  gap: 0.5rem;

  .propertyItem {
    display: flex;
    width: 64rem;
    height: 2rem;
  }
  
}
