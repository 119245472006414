.addCardLine {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 1rem;
  height: 1rem;

  .divider {
    display: block;
    width: 1rem;
    height: 0.0625rem;
  }

  .button {
    display: none;
  }

  .divider1 {
    display: none;
  }
  
  &:hover {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
  
    .divider {
      display: none;
    }

    .button {
      display: unset;
      width: 1rem;
      height: 1rem;
    }

  }
}

.hiddenHandler {
  opacity: 0;
}