.textModalDefault {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem 2rem 2rem 2rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  width: 26.625rem;

  .headingContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .heading {
    display: flex;
    width: 20.5625rem;
  }
  
  .primaryButton {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.8125rem;
  }
  
  .divider {
    display: block;
    width: unset;
    align-self: stretch;
    margin-top: 0.8125rem;
  }
  
  .text {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.8125rem;
  }
  
  .buttonContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0.8125rem;
  }
  
  .primaryButton1 {
    display: flex;
  }
  
  .secondaryButton {
    display: flex;
    margin-left: 0.875rem;
  }
  
}

.textModalDestructive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem 2rem 2rem 2rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  width: 26.625rem;

  .headingContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .heading {
    display: flex;
    width: 20.5625rem;
  }
  
  .primaryButton {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.8125rem;
  }
  
  .divider {
    display: block;
    width: unset;
    align-self: stretch;
    margin-top: 0.8125rem;
  }
  
  .text {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.8125rem;
  }
  
  .buttonContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0.8125rem;
  }
  
  .button {
    display: flex;
  }
  
  .secondaryButton {
    display: flex;
    margin-left: 0.875rem;
  }
  
}
