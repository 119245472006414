.responseItemCollapsedExpandedViewAdd {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.75rem 0rem 0.75rem;
  background-color: rgb(39, 43, 51);
  height: 1.5rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
    margin-left: 0.25rem;
  }
  
  .name {
    display: flex;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .responseType {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .button1 {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
  }
  
}

.responseItemCollapsedExpandedViewAdded {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.75rem 0rem 0.75rem;
  background-color: rgb(39, 43, 51);
  height: 1.5rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
    margin-left: 0.25rem;
  }
  
  .name {
    display: flex;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .responseType {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
  }
  
}

.responseItemCollapsedExpandedViewDefault {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.75rem 0rem 0.75rem;
  background-color: rgb(17, 18, 22);
  height: 1.5rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
    margin-left: 0.25rem;
  }
  
  .name {
    display: flex;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .responseType {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
}

.responseItemCollapsedPanelAdd {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0rem 0rem 0.75rem;
  background-color: rgb(39, 43, 51);
  height: 1.5rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .name {
    display: flex;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .responseType {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .rowHoverCap {
    display: flex;
    width: 2.875rem;
    height: 1.5rem;
    margin-left: 0.25rem;
  }
  
}

.responseItemCollapsedPanelDefault {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.75rem 0rem 0.75rem;
  background-color: rgb(17, 18, 22);
  height: 1.5rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .name {
    display: flex;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .responseType {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
}

.responseItemDefaultExpandedViewAdd {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.75rem 0rem 2rem;
  background-color: rgb(39, 43, 51);
  height: 1.5rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .name {
    display: flex;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .responseType {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
  }
  
}

.responseItemDefaultExpandedViewAdded {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.75rem 0rem 2rem;
  background-color: rgb(39, 43, 51);
  height: 1.5rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .name {
    display: flex;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .responseType {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
  }
  
}

.responseItemDefaultExpandedViewDefault {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.75rem 0rem 2rem;
  background-color: rgb(17, 18, 22);
  height: 1.5rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .name {
    display: flex;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .responseType {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
}

.responseItemDefaultPanelAdd {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0rem 0rem 2rem;
  background-color: rgb(39, 43, 51);
  height: 1.5rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
  }
  
  .name {
    display: flex;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .responseType {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .rowHoverCap {
    display: flex;
    width: 2.875rem;
    height: 1.5rem;
    margin-left: 0.25rem;
  }
  
}

// MANUAL OVERRIDE START
.responseItemDefaultPanelAdded {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0rem 0rem 2rem;
  background-color: rgb(39, 43, 51);
  height: 1.5rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
  }
  
  .name {
    display: flex;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .responseType {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .rowHoverCap {
    display: flex;
    width: 2.875rem;
    height: 1.5rem;
    margin-left: 0.25rem;
  }
  
}
// MANUAL OVERRIDE END

.responseItemDefaultPanelDefault {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.75rem 0rem 2rem;
  background-color: rgb(17, 18, 22);
  height: 1.5rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
  }
  
  .name {
    display: flex;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .responseType {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
}

.responseItemExpandedExpandedViewAdd {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.75rem 0rem 0.75rem;
  background-color: rgb(39, 43, 51);
  height: 1.5rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
    margin-left: 0.25rem;
  }
  
  .name {
    display: flex;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .responseType {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .button1 {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
  }
  
}

.responseItemExpandedExpandedViewAdded {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.75rem 0rem 0.75rem;
  background-color: rgb(39, 43, 51);
  height: 1.5rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
    margin-left: 0.25rem;
  }
  
  .name {
    display: flex;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .responseType {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
  }
  
}

.responseItemExpandedExpandedViewDefault {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.75rem 0rem 0.75rem;
  background-color: rgb(17, 18, 22);
  height: 1.5rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
    margin-left: 0.25rem;
  }
  
  .name {
    display: flex;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .responseType {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
}

.responseItemExpandedPanelAdd {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0rem 0rem 0.75rem;
  background-color: rgb(39, 43, 51);
  height: 1.5rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .name {
    display: flex;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .responseType {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .rowHoverCap {
    display: flex;
    width: 2.875rem;
    height: 1.5rem;
    margin-left: 0.25rem;
  }
  
}

.responseItemExpandedPanelDefault {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.75rem 0rem 0.75rem;
  background-color: rgb(17, 18, 22);
  height: 1.5rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .name {
    display: flex;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .responseType {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
}
