.pageLabelItemList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  .pageLabelItem {
    display: flex;
    width: -webkit-fill-available; // MANUAL OVERRIDE
    width: -moz-available; // MANUAL OVERRIDE
    height: 2rem;
    // align-self: stretch; // MANUAL OVERRIDE
    white-space: nowrap; // MANUAL OVERRIDE
  }
  
}
