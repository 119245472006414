.emptyGroupBlockEmptyEndpoints {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 3rem 3rem 3rem 3rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  outline: 1px solid rgb(63, 71, 86);
  outline-offset: 0;

  .emptyStateContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .icon {
    display: block;
    width: 5rem;
    height: 5rem;
  }
  
  .textContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .label {
    display: flex;
    width: unset;
    align-self: center; // MANUAL OVERRIDE
  }
  
  .instructions {
    display: flex;
    width: unset;
    align-self: center; // MANUAL OVERRIDE
    margin-top: 0.25rem;
  }
  
  .button {
    display: flex;
    margin-top: 1.5rem;
  }
  
}

.emptyGroupBlockEmptyGroup {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 3rem 3rem 3rem 3rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  outline: 1px solid rgb(63, 71, 86);
  outline-offset: 0;

  .emptyStateContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .icon {
    display: block;
    width: 5rem;
    height: 5rem;
  }
  
  .textContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: center; // MANUAL OVERRIDE
    margin-top: 0.5rem;
  }
  
  .label {
    display: flex;
    width: unset;
    align-self: center; // MANUAL OVERRIDE
  }
  
  .instructions {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .button {
    display: flex;
    margin-top: 1.5rem;
  }
  
}
