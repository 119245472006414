.styleSettingsHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  background-color: rgb(26, 28, 34);

  .styleSettingsToggle {
    display: flex;
    width: 20rem;
  }
  
  .selectedContainer {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
}
