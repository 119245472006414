.button {
  cursor: pointer;
}

.buttonPrimarySubdued {
  &:active,
  &:hover {
    .icon {
      path {
        fill: rgb(233, 236, 245);
      }
    }
    .variableIcon {
      path[id=Content2], [id=Content2] path {
        display: block;
        fill: transparent;
      }
      
      path[id=Content1], [id=Content1] path {
        display: block;
        fill: rgb(233, 236, 245);
      }
    }
    .text p {
      color: rgb(233, 236, 245);
    }
  }
}

.buttonPrimary {
  &:disabled {
    .text p {
      color: rgb(135, 141, 155);
    }
  }
}

.buttonSecondary {
  &:active,
  &:focus-visible,
  &:hover {
    .text p {
      color: rgb(233, 236, 245);
    }
  }
}

.buttonDestructive {
  &:disabled {
    .text p {
      color: rgb(135, 141, 155);
    }
  }
}

.buttonDestructivePrimary {
  &:disabled {
    .text p {
      color: rgb(135, 141, 155);
    }
  }
}
