.image {
  overflow: hidden;
  display: block;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 2rem;
  height: 2rem;

}
