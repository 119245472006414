$borderColour: #535DB4;

.elementRenderer {
  position: relative;
  box-sizing: border-box;
  display: flex;
}

.hovered {
  outline: 0.0625rem solid $borderColour;
  z-index: 10;
}

.selected {
  outline: 0.125rem solid $borderColour;
  z-index: 20;
}

.dragging {
  filter: grayscale(1);
}

.dropForbidden {
  filter: grayscale(1);
}
