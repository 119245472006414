.testResponseItemCollapsed {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0rem 0rem 0.75rem;
  background-color: rgb(17, 18, 22);
  height: 2.5rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
  }
  
  .textContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .name {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .buttonContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.5rem 0.75rem 0.5rem 0.75rem;
    background-color: rgb(17, 18, 22);
    width: 8.75rem;
    margin-left: 0.5rem;
  }
  
  .dropdownButton {
    display: flex;
    width: 7.25rem;
    height: 2rem;
  }
  
  &:hover {
    background-color: rgb(26, 28, 34);
  
    .buttonContent {
      background-color: rgb(26, 28, 34);
    }
    
  }
}

.testResponseItemDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0rem 0rem 2.25rem;
  background-color: rgb(17, 18, 22);
  height: 2.5rem;

  .textContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
  }
  
  .name {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .buttonContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.5rem 0.75rem 0.5rem 0.75rem;
    background-color: rgb(17, 18, 22);
    width: 8.75rem;
    margin-left: 0.5rem;
  }
  
  .dropdownButton {
    display: flex;
    width: 7.25rem;
    height: 2rem;
  }
  
  &:hover {
    background-color: rgb(26, 28, 34);
  
    .buttonContent {
      background-color: rgb(26, 28, 34);
    }
    
  }
}

.testResponseItemExpanded {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0rem 0rem 0.75rem;
  background-color: rgb(17, 18, 22);
  height: 2.5rem;

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
  }
  
  .textContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .name {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .buttonContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.5rem 0.75rem 0.5rem 0.75rem;
    background-color: rgb(17, 18, 22);
    width: 8.75rem;
    margin-left: 0.5rem;
  }
  
  .dropdownButton {
    display: flex;
    width: 7.25rem;
    height: 2rem;
  }
  
  &:hover {
    background-color: rgb(26, 28, 34);
  
    .buttonContent {
      background-color: rgb(26, 28, 34);
    }
    
  }
}
