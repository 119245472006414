.pageLabelItemDefaultDefaultDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 2.25rem; // MANUAL OVERRIDE
  background-color: rgb(26, 28, 34);
  height: 2rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
  }
  
  .text {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
}

.pageLabelItemDefaultDefaultHomepage {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 1rem; // MANUAL OVERRIDE
  background-color: rgb(26, 28, 34);
  height: 2rem;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .text {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
}

.pageLabelItemDefaultEditableSelectedDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 2rem; // MANUAL OVERRIDE
  background-color: rgb(65, 71, 85);
  height: 2rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
  }
  
  .textInput {
    display: flex;
    width: unset;
    height: 1.5rem;
    flex: 1 1 auto;
    max-width: 11.9rem; // MANUAL OVERRIDE
  }
  
}

.pageLabelItemDefaultEditableSelectedHomepage {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 1rem; // MANUAL OVERRIDE
  background-color: rgb(65, 71, 85);
  height: 2rem;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
  }
  
  .textInput {
    display: flex;
    width: unset;
    height: 1.5rem;
    flex: 1 1 auto;
    max-width: 11.9rem; // MANUAL OVERRIDE
  }
  
}

.pageLabelItemDefaultSelectedDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 2.25rem; // MANUAL OVERRIDE
  background-color: rgb(65, 71, 85);
  height: 2rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
  }
  
  .text {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
}

.pageLabelItemDefaultSelectedHomepage {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 1rem; // MANUAL OVERRIDE
  background-color: rgb(65, 71, 85);
  height: 2rem;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .text {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
}

.pageLabelItemShowMenuDefaultDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 1rem 0rem 2.25rem;
  background-color: rgb(26, 28, 34);
  height: 2rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
  }
  
  .text {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
  }
  
  // MANUAL OVERRIDE STARTS
  // &:hover {
  
  //   .text {
  //     display: none;
  //     width: unset;
  //     height: unset;
  //   }
    
  // }
  // MANUAL OVERRIDE ENDS
}

.pageLabelItemShowMenuDefaultHomepage {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
  background-color: rgb(26, 28, 34);
  height: 2rem;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .text {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
  }
  
  // MANUAL OVERRIDE STARTS
  // &:hover {
  
  //   .text {
  //     display: none;
  //     width: unset;
  //     height: unset;
  //   }
    
  // }
  // MANUAL OVERRIDE ENDS
}

.pageLabelItemShowMenuEditableSelectedDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 1rem 0rem 2rem;
  background-color: rgb(65, 71, 85);
  height: 2rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
  }
  
  .textInput {
    display: flex;
    width: unset;
    height: 1.5rem;
    flex: 1 1 auto;
    max-width: 11.9rem; // MANUAL OVERRIDE
  }
  
  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
  }
  
}

.pageLabelItemShowMenuEditableSelectedHomepage {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
  background-color: rgb(65, 71, 85);
  height: 2rem;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
  }
  
  .textInput {
    display: flex;
    width: unset;
    height: 1.5rem;
    flex: 1 1 auto;
    max-width: 11.9rem; // MANUAL OVERRIDE
  }
  
  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
  }
  
}

.pageLabelItemShowMenuSelectedDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 1rem 0rem 2.25rem;
  background-color: rgb(65, 71, 85);
  height: 2rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
  }
  
  .text {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
  }
  
}

.pageLabelItemShowMenuSelectedHomepage {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
  background-color: rgb(65, 71, 85);
  height: 2rem;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .text {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
  }
  
}

// MANUAL OVERRIDE STARTS
.contextMenuItem {
  width: max-content;
  cursor: pointer;
}
// MANUAL OVERRIDE STARTS