.root {
  display: flex;
  // background-color: black;
  justify-content: flex-start;
  padding: unset;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .workspace {
    display: flex;
    padding: 0rem;
    width: 100%;
    height: 100%;
    justify-content: center;
    overflow: auto;
    scrollbar-gutter: stable;
  }

  .canvasContainer {
    position: relative;
    box-sizing: border-box;
  }

  .canvas {
    position: relative;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    transform-origin: left top;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
  }
}
