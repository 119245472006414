.canvas {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(7, 7, 9);

  *, *::before, *::after {
    box-sizing: border-box;
  }
}
