.labelDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .text {
    display: flex;
  }
  
}

.labelRequired {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .text {
    display: flex;
  }
  
  .text1 {
    display: flex;
    margin-left: 0.25rem;
  }
  
}
