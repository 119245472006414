.configureGroupMenuDefault {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  outline: 1px solid rgb(46, 51, 62);
  outline-offset: 0;
  box-shadow: 3px 4px 12px rgba(0, 0, 0, 0.5);
  width: 18.75rem;

  .menuHeader {
    display: flex;
    width: unset;
    height: 2.25rem;
    align-self: stretch;
  }
  
  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  .menuContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    align-self: stretch;
  }
  
  .nameContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .label {
    display: flex;
    width: 4.5rem;
  }
  
  .inputField {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.4375rem;
    // MANUAL OVERRIDE START
    input {
      width: unset
    }
    // MANUAL OVERRIDE END
  }
  
  .baseURLContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .label1 {
    display: flex;
    width: 4.5rem;
  }
  
  .inputField1 {
    display: flex;
    width: unset;
    height: 6rem;
    flex: 1 1 auto;
    margin-left: 0.4375rem;
  }
  
  .buttonContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .buttonGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 8.5rem;
  }
  
  .secondaryButton {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
  }
  
  .primaryButton {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.configureGroupMenuRemoveable {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  outline: 1px solid rgb(46, 51, 62);
  outline-offset: 0;
  box-shadow: 3px 4px 12px rgba(0, 0, 0, 0.5);
  width: 18.75rem;

  .menuHeader {
    display: flex;
    width: unset;
    height: 2.25rem;
    align-self: stretch;
  }
  
  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  .menuContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    align-self: stretch;
  }
  
  .nameContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .label {
    display: flex;
    width: 4.5rem;
  }
  
  .inputField {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.4375rem;
    // MANUAL OVERRIDE START
    input {
      width: unset
    }
    // MANUAL OVERRIDE END
  }
  
  .baseURLContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .label1 {
    display: flex;
    width: 4.5rem;
  }
  
  .inputField1 {
    display: flex;
    width: unset;
    height: 6rem;
    flex: 1 1 auto;
    margin-left: 0.4375rem;
  }
  
  .buttonContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .button {
    display: flex;
    width: 4rem;
    height: 2rem;
  }
  
  .buttonGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 8.5rem;
    margin-left: 0.75rem;
  }
  
  .secondaryButton {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
  }
  
  .primaryButton {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}
