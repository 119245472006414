.keyValueTableFilledTextTextValue {
  overflow: auto; // MANUAL OVERRIDE
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  outline: 1px solid rgb(39, 43, 51);
  outline-offset: 0;

  .headerContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0rem 0.75rem 0rem 0.75rem;
    background-color: rgb(39, 43, 51);
    width: unset;
    height: 2rem;
    align-self: stretch;
  }
  
  .name {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .type {
    display: flex;
    width: 6rem;
    margin-left: 0.75rem;
  }
  
  .value {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.75rem;
  }
  
  .tableContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    width: unset;
    align-self: stretch;
  }
  
  .tableRowList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
