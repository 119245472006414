.paddingMarginsPanelCollapsed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20rem;

  .panelHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.paddingMarginsPanelExpanded {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20rem;

  .panelHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .panelContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    align-self: stretch;
  }
  
  .paddingMarginGrid {
    display: flex;
    width: 18.5rem;
  }
  
}
