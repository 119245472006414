.emptyStateInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 1rem 1.5rem 1rem;
  border-radius: 4px;
  background-color: rgb(39, 43, 51);

  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
