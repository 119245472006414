.backHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .headerContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0rem 0.5rem 0rem 0.5rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    height: 2.5rem;
    align-self: stretch;
  }
  
  .button {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.25rem;
  }
  
}
