.rowLabelSelectedVariableClear {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.125rem 0rem 0.125rem;
  border-radius: 4px;
  width: 4.5rem;
  height: 1.875rem;

  .text {
    display: flex;
  }
  
  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.125rem;
  }
  
}

.rowLabelSelectedVariablePanel {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.125rem 0rem 0.125rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  width: 4.5rem;
  height: 1.875rem;

  .text {
    display: flex;
  }
  
  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.125rem;
  }
  
}

.rowLabelDefaultDefaultClear {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.125rem 0rem 0.125rem;
  border-radius: 4px;
  width: 4.5rem;
  height: 1.875rem;

  .text {
    display: flex;
  }
  
}

.rowLabelDefaultDefaultPanel {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.125rem 0rem 0.125rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  width: 4.5rem;
  height: 1.875rem;

  .text {
    display: flex;
  }
  
}

.rowLabelDefaultVariableClear {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.125rem 0rem 0.125rem;
  border-radius: 4px;
  width: 4.5rem;
  height: 1.875rem;

  .text {
    display: flex;
  }
  
  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.125rem;
  }
  
}

.rowLabelDefaultVariablePanel {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.125rem 0rem 0.125rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  width: 4.5rem;
  height: 1.875rem;

  .text {
    display: flex;
  }
  
  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 0.125rem;
  }
  
}
