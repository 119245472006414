.layoutPanel {

  .toggleItemList {
    padding: initial;
  }

  .dropdownButton,
  .dropdownButton1 {
    width: 100%;
  }

  .dropdownButton2 {
    width: 6.5rem;
    flex: unset;
  }

  .dropdownButton3 {
    width: 6.5rem;
    flex: unset;
  }

  .hideSection {
    display: none;
  }
}