.styleSettingsBlockLogicSettingsPanel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 20rem;

  .logicSettingsPanel {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.styleSettingsBlockSettingsPanel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 0rem 0.75rem 0rem;
  background-color: rgb(26, 28, 34);
  width: 20rem;

  .styleSettingsHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .settingsPanel {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.styleSettingsBlockStylePanel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 0rem 0.75rem 0rem;
  background-color: rgb(26, 28, 34);
  width: 20rem;

  .styleSettingsHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .stylePanel {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

*, *::before, *::after {
  box-sizing: border-box;
}