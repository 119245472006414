$paddingLeft: 0.75rem; // MANUAL OVERRIDE
$paddingRight: 0.5rem; // MANUAL OVERRIDE

.spindlLogo {
  // MANUAL OVERRIDE STARTS
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 $paddingRight 0 $paddingLeft; 
  background-color: rgb(26, 28, 34);
  width: calc(4rem + $paddingLeft + $paddingRight);
  height: 100%;
  cursor: pointer;
  // MANUAL OVERRIDE ENDS

  path[id=Content1], [id=Content1] path {
    display: block;
    fill: rgb(233, 236, 245);
  }
  
  path[id=Content2], [id=Content2] path {
    display: block;
    fill: rgb(216, 36, 0);
  }
  
  // MANUAL OVERRIDE STARTS
  &:active {
    background-color: rgb(65, 71, 85);
  }
  // MANUAL OVERRIDE ENDS
}
