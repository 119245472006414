.testVariableBlockEmpty {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 3rem 3rem 3rem 3rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  outline: 1px solid rgb(63, 71, 86);
  outline-offset: 0;
  width: 56.9375rem;

  .labelContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 5.8125rem;
  }
  
  .text {
    display: flex;
  }
  
  .emptyStateInfo {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
}

.testVariableBlockFilled {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 3rem 3rem 3rem 3rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  outline: 1px solid rgb(63, 71, 86);
  outline-offset: 0;
  width: 56.9375rem;

  .labelContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 5.8125rem;
  }
  
  .text {
    display: flex;
  }
  
  .keyValueTableFilledTextTextValue {
    display: flex;
    width: unset;
    height: 15.5rem;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
}

.testVariableBlockCollapsed {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 3rem 1.5rem 3rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  outline: 1px solid rgb(63, 71, 86);
  outline-offset: 0;
  width: 56.9375rem;

  .headerContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .labelContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .text {
    display: flex;
  }
  
  .button {
    display: flex;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.5rem;
  }
  
}

.testVariableBlockDisabled {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 3rem 1.5rem 3rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  outline: 1px solid rgb(63, 71, 86);
  outline-offset: 0;
  width: 56.9375rem;

  .headerContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .labelContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .text {
    display: flex;
  }
  
  .button {
    display: flex;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.5rem;
  }
  
}

.testVariableBlockExpanded {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 3rem 1.5rem 3rem;
  border-radius: 4px;
  background-color: rgb(26, 28, 34);
  outline: 1px solid rgb(63, 71, 86);
  outline-offset: 0;
  width: 56.9375rem;

  .headerContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .labelContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .text {
    display: flex;
  }
  
  .button {
    display: flex;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.5rem;
  }
  
  .codeSpace {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
}
