.methodChipDelete {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgba(140, 35, 42, 0.6000000238418579);
  outline: 1px solid rgb(204, 51, 61);
  outline-offset: 0;
  width: 4.75rem;
  height: 2rem;

  .text {
    display: flex;
  }
  
}

.methodChipGet {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  border-radius: 4px;
  background-color: rgba(58, 118, 77, 0.6000000238418579);
  outline: 1px solid rgb(83, 172, 112);
  outline-offset: 0;
  width: 4.75rem;
  height: 2rem;

  .text {
    display: flex;
  }
  
}

.methodChipPatch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  border-radius: 4px;
  background-color: rgba(106, 55, 120, 0.6000000238418579);
  outline: 1px solid rgb(155, 80, 175);
  outline-offset: 0;
  width: 4.75rem;
  height: 2rem;

  .text {
    display: flex;
  }
  
}

.methodChipPost {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  border-radius: 4px;
  background-color: rgba(163, 120, 12, 0.6000000238418579);
  outline: 1px solid rgb(238, 176, 17);
  outline-offset: 0;
  width: 4.75rem;
  height: 2rem;

  .text {
    display: flex;
  }
  
}

.methodChipPut {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  border-radius: 4px;
  background-color: rgba(53, 60, 121, 0.6000000238418579);
  outline: 1px solid rgb(83, 93, 180);
  outline-offset: 0;
  width: 4.75rem;
  height: 2rem;

  .text {
    display: flex;
  }
  
}
