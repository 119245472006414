.toggleItemListLargeTextDefault {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.25rem 0.25rem 0.25rem 0.25rem;
  border-radius: 4px;
  background-color: rgb(7, 7, 9);

  .toggleItem {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
  }
  
}

.toggleItemListSmallIconDefault {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.125rem 0.125rem 0.125rem 0.125rem;
  border-radius: 4px;
  background-color: rgb(7, 7, 9);

  .toggleItem {
    display: flex;
    width: unset;
    height: 1.75rem;
    flex: 1 1 auto;
  }
  
}

.toggleItemListSmallIconTextDefault {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.125rem 0.125rem 0.125rem 0.125rem;
  border-radius: 4px;
  background-color: rgb(7, 7, 9);

  .toggleItem {
    display: flex;
    width: unset;
    height: 1.75rem;
    flex: 1 1 auto;
  }
  
}

.toggleItemListSmallTextDefault {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.125rem 0.125rem 0.125rem 0.125rem;
  border-radius: 4px;
  background-color: rgb(7, 7, 9);

  .toggleItem {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
  }
  
}
