.variableCardLargeDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 4px;
  background-color: rgb(7, 7, 9);
  height: 3.5rem;

  .variableName {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  &:active {
    background-color: rgb(65, 71, 85);
  
  }
  &:disabled {
    background-color: rgb(46, 51, 62);
  
  }
  &:hover {
    background-color: rgb(39, 43, 51);
  
  }
}

.variableCardSmallGlobal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  .cardContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    height: 1.9375rem;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .scopeIcon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .variableName {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .editIcon {
    display: none;
  }
  
  &:active {
    align-items: flex-start;
  
    .divider {
      width: 14.9375rem;
      height: 0.0625rem;
    }
    
    .cardContent {
      background-color: rgb(65, 71, 85);
    }
    
    .editIcon {
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      margin-left: 0.25rem;
    }
    
  }
  &:hover {
    align-items: flex-start;
  
    .divider {
      width: 14.9375rem;
      height: 0.0625rem;
    }
    
    .cardContent {
      background-color: rgb(39, 43, 51);
    }
    
    .editIcon {
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      margin-left: 0.25rem;
    }
    
  }
}

.variableCardSmallPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  .cardContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    height: 1.9375rem;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .scopeIcon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .variableName {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .editIcon {
    display: none;
  }
  
  &:active {
    align-items: flex-start;
  
    .divider {
      width: 14.9375rem;
      height: 0.0625rem;
    }
    
    .cardContent {
      background-color: rgb(65, 71, 85);
    }
    
    .editIcon {
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      margin-left: 0.25rem;
    }
    
  }
  &:hover {
    align-items: flex-start;
  
    .divider {
      width: 14.9375rem;
      height: 0.0625rem;
    }
    
    .cardContent {
      background-color: rgb(39, 43, 51);
    }
    
    .editIcon {
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      margin-left: 0.25rem;
    }
    
  }
}

.variableCardSmallUnselected {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  .cardContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    height: 1.9375rem;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .scopeIcon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .variableName {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .editIcon {
    display: none;
  }
  
  &:active {
    align-items: flex-start;
  
    .divider {
      width: 14.9375rem;
      height: 0.0625rem;
    }
    
    .cardContent {
      background-color: rgb(65, 71, 85);
    }
    
    .editIcon {
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      margin-left: 0.25rem;
    }
    
  }
  &:hover {
    align-items: flex-start;
  
    .divider {
      width: 14.9375rem;
      height: 0.0625rem;
    }
    
    .cardContent {
      background-color: rgb(39, 43, 51);
    }
    
    .editIcon {
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      margin-left: 0.25rem;
    }
    
  }
}
