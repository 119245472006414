.endpointCardList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.75rem;
  overflow: auto;

  .endpointCard {
    display: flex;
    width: unset;
    height: 4.5rem;
    align-self: stretch;
  }
  
}
