.variableLayerItemDefaultDefault {
    .variableType {
        width: 4rem;
    }
}

.variableLayerItemSelectedDefault {
    .variableType {
        width: 4rem;
    }
}