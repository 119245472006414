.layoutPanelCollapsed {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 20rem;

  .panelHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.layoutPanelExpanded {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 20rem;

  .panelHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .layoutContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    align-self: stretch;
  }
  
  .spacingContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .rowLabel {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .dropdownButton {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .centreContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .alignmentContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  .leftContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: 10.25rem;
  }
  
  .direction {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .rowLabel1 {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .toggleItemList {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .gap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 10.25rem;
    margin-top: 0.5rem;
  }
  
  .rowLabel2 {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .inputField {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .gap1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 10.25rem;
    margin-top: 0.5rem;
  }
  
  .rowLabel3 {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .inputField1 {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .alignmentGrid {
    display: flex;
    margin-left: 1rem;
  }
  
  .wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .rowLabel4 {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .toggleItemList1 {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .positionContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .positiom {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .rowLabel5 {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .dropdownButton1 {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .positionAlignment {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0rem 0rem 0rem 5rem;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .leftContent1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: unset;
    flex: 1 1 auto;
  }
  
  .horizontalPosition {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .dropdownButton2 {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
  }
  
  .inputField2 {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .verticalPosition {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .dropdownButton3 {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
  }
  
  .inputField3 {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}
