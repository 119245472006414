.replaceRowImageDefaultDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(26, 28, 34);

  .rowLabel {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .replaceButton {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.replaceRowImageDefaultVariable {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(26, 28, 34);

  .rowLabel {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .replaceButton {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.replaceRowVariableButtonDefaultVariable {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(26, 28, 34);

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
  }
  
  .inputField {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.replaceRowVariableButtonDefaultVariableVertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .rowLabel {
    display: flex;
    width: unset;
    height: 1.875rem;
    align-self: stretch;
  }
  
  .replaceButton {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
}

.replaceRowVariableButtonEditableVariable {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(26, 28, 34);

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
  }
  
  .inputField {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.replaceRowVariableButtonSelectedVariable {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(26, 28, 34);

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
  }
  
  .inputField {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.replaceRowVariableButtonUploadedVariable {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(26, 28, 34);

  .button {
    display: flex;
    width: 1rem;
    height: 1rem;
  }
  
  .propertyItem {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.replaceRowVariableDropdownDefaultVariableVertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .rowLabel {
    display: flex;
    width: unset;
    height: 1.875rem;
    align-self: stretch;
  }
  
  .dropdownButton {
    display: flex;
    width: unset;
    height: 2rem;
    align-self: stretch;
  }
  
}

.replaceRowVariableImageSelectedVariable {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(26, 28, 34);

  .rowLabel {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .replaceButton {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.replaceRowVariableImageUploadedVariable {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(26, 28, 34);

  .rowLabel {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .replaceButton {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.replaceRowVariableTextDefaultVariable {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(26, 28, 34);

  .rowLabel {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .inputField {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.replaceRowVariableTextDefaultVariableVertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .rowLabel {
    display: flex;
    width: unset;
    height: 1.875rem;
    align-self: stretch;
  }
  
  .inputField {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
}

.replaceRowVariableTextEditableVariable {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(26, 28, 34);

  .rowLabel {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .inputField {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.replaceRowVariableTextEditableVariableVertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .rowLabel {
    display: flex;
    width: unset;
    height: 1.875rem;
    align-self: stretch;
  }
  
  .inputField {
    display: flex;
    width: unset;
    height: 2rem;
    align-self: stretch;
  }
  
}

.replaceRowVariableTextSelectedVariable {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(26, 28, 34);

  .rowLabel {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .inputField {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.replaceRowVariableTextSelectedVariableVertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .rowLabel {
    display: flex;
    width: unset;
    height: 1.875rem;
    align-self: stretch;
  }
  
  .inputField {
    display: flex;
    width: unset;
    height: 2rem;
    align-self: stretch;
  }
  
}

.replaceRowVariableTextUploadedVariable {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(26, 28, 34);

  .rowLabel {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .propertyItem {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.replaceRowVariableTextUploadedVariableVertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .rowLabel {
    display: flex;
    width: unset;
    height: 1.875rem;
    align-self: stretch;
  }
  
  .propertyItem {
    display: flex;
    width: unset;
    height: 2rem;
    align-self: stretch;
  }
  
}
