.typographyPanelCollapsed {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 20rem;

  .panelHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.typographyPanelExpanded {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 20rem;

  .panelHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .typographyContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    align-self: stretch;
  }
  
  .fontContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .font {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .dropdownButton {
    display: flex;
    width: unset;
    height: 1rem; // MANUAL OVERRIDE
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .weightSizeContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .weight {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .weightDropdown {
    display: flex;
    width: 6.25rem;
    height: 2rem;
    margin-left: 0.5rem;
  }
  
  .size {
    display: flex;
    width: 3rem;
    min-width: 3rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  .sizeField {
    display: flex;
    width: 3.25rem; // MANUAL OVERRIDE
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .spacingContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .spacing {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .letterSpace {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.5rem;
  }
  
  .letterSpaceField {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .lineHeight {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.5rem;
  }
  
  .lineHeightField {
    display: flex;
    width: unset;
    height: 2rem;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .colourRow {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .alignContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .style {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .lineToggle {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .styleContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .style1 {
    display: flex;
    width: 4.5rem;
    height: 1.875rem;
  }
  
  .italicsToggle {
    display: flex;
    width: 4.5rem;
    margin-left: 0.5rem;
    button { // MANUAL OVERRIDE
      width: 1rem;
    }
  }
  
  .lineToggle1 {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
    button { // MANUAL OVERRIDE
      width: 1rem;
    }
  }
  
}
