.layerLabelItemList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch; // MANUAL OVERRIDE

  .layerLabelItem {
    display: flex;
    width: unset;
    height: 2rem;
    align-self: stretch;
    white-space: nowrap; // MANUAL OVERRIDE
  }
  
}
