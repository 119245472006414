.variableSectionCollapsed {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(26, 28, 34);
  width: 48rem;

  .expandedSectionHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.variableSectionExpanded {
  overflow: visible; // MANUAL OVERRIDE
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(26, 28, 34);
  height: 20.25rem;

  .expandedSectionHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .logicVariableTable {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-top: 0.5rem;
  }
  
}
