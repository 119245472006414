.leftPanel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 0rem 0.75rem 0rem;
  background-color: rgb(26, 28, 34);
  width: 15.0625rem;
  align-self: stretch; // MANUAL OVERRIDE
  overflow: auto; // MANUAL OVERRIDE
  
  // MANUAL OVERRIDE STARTS
  &.panelHidden {
    width: 0;
    overflow: hidden;
  }
  // MANUAL OVERRIDE ENDS

  .designLayersPagesHeader {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 0.75rem 0rem;
    background-color: rgb(26, 28, 34);
    width: unset;
    align-self: stretch;
  }
  
  .styleSettingsToggle {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .leftPanel {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
}
